/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */

import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { ContractReceipt } from 'ethers'
import useWeb3 from 'hooks/useWeb3'
import { useReferralContract } from './useContract'

const SUFFIX = {
  V1: {
    GET_PROFILE: `customers`,
    CREATE_PROFILE: `ref`,
    GET_REF_CHILD: 'ref/children',
    GET_HISTORY_REWARD: `ref/history`,
    ADD_PARENT_REF: 'ref',
    GET_REF_SUMMARY: 'ref/summary',
    WITHDRAW: 'ref/withdraw/sign',
    WITHDRAW_COMPLETE: 'ref/withdraw/complete',
    WITHDRAW_HISTORY: 'ref/withdraw/history',
    RANKING: 'ref/ranking',
  },
}

const useReferral = () => {
  const { account } = useWeb3React()
  // const account = '0x92bbc681d19a942e8bccac7158684ff1bfe56247'
  const web3 = useWeb3()
  const referralContract = useReferralContract()

  const DOMAIN_URL = process.env.REACT_APP_API_DOMAIN

  const getProfile = async () => {
    try {
      const data = await axios.post(`${DOMAIN_URL}/${SUFFIX.V1.GET_PROFILE}`, { address: account })
      return data?.data?.data
    } catch (e) {
      console.log('Get Ref profile: ', e)
      return null
    }
  }

  const createProfile = async () => {
    try {
      const data = await axios.post(`${DOMAIN_URL}/${SUFFIX.V1.CREATE_PROFILE}`, { address: account })

      return data.data
    } catch (e) {
      console.log('create Ref profile when connect wallet: ', e)
      return null
    }
  }

  const getRefHistoryReward = async (type, limit = 5, page) => {
    try {
      const data = await axios.get(
        `${DOMAIN_URL}/${SUFFIX.V1.GET_HISTORY_REWARD}?address=${account}&type=${type}&limit=${limit}&page=${page}`,
      )

      return { data: data.data.data.histories, total: data.data.data.total }
    } catch (e) {
      console.log('create Ref profile when connect wallet: ', e)
      return null
    }
  }

  const getRefChildren = async (limit = 5, page) => {
    try {
      const data = await axios.get(
        `${DOMAIN_URL}/${SUFFIX.V1.GET_REF_CHILD}?address=${account}&limit=${limit}&page=${page}`,
      )
      return { data: data.data.data.children, total: data.data.data.total }
    } catch (e) {
      console.log('create Ref profile when connect wallet: ', e)
      return null
    }
  }

  const getRefSummary = async () => {
    try {
      const data = await axios.get(`${DOMAIN_URL}/${SUFFIX.V1.GET_REF_SUMMARY}?address=${account}`)

      return data.data.data
    } catch (e) {
      console.log('create Ref profile when connect wallet: ', e)
      return null
    }
  }

  const addParentRef = async (refCode, hash, token) => {
    try {
      if (!refCode) {
        return
      }
      const data = await axios.post(`${DOMAIN_URL}/${SUFFIX.V1.ADD_PARENT_REF}`, {
        address: account,
        refCode,
        token,
        hash,
      })

      return data.data
    } catch (e) {
      console.log('addParentRef when connect wallet: ', e)
      return null
    }
  }

  const withdraw = async () => {
    const { data } = await axios.post(`${DOMAIN_URL}/${SUFFIX.V1.WITHDRAW}`, { address: account })
    const { withdrawToken, amount, address, timeout, signature, nonce } = data.data
    const { v, r, s } = signature

    const weiAmount = (+amount).toLocaleString('fullwide', { useGrouping: false })
    await referralContract.methods
      .withdrawPermit(withdrawToken, weiAmount, address, timeout, v, r, s)
      .send({ from: address })
      .on('receipt', async (receipt: ContractReceipt) => {
        if (receipt.status) {
          await axios.post(`${DOMAIN_URL}/${SUFFIX.V1.WITHDRAW_COMPLETE}`, { address: account, nonce })
        }
      })
  }

  const getWithdrawHistory = async () => {
    const { data } = await axios.get(`${DOMAIN_URL}/${SUFFIX.V1.WITHDRAW_HISTORY}?address=${account}`)
    return data.data
  }

  const getRankingList = async (sortField, direction, page, limit) => {
    const { data } = await axios.get(`${DOMAIN_URL}/${SUFFIX.V1.RANKING}`, {
      params: {
        sortField,
        direction,
        page,
        limit,
      },
    })
    console.log('data:', data)

    return { data: data.data.data, total: data.data.total, totalPage: data.data.totalPage }
  }

  return {
    getProfile,
    getRefChildren,
    getRefHistoryReward,
    createProfile,
    getRefSummary,
    addParentRef,
    withdraw,
    getWithdrawHistory,
    getRankingList,
  }
}

export default useReferral
