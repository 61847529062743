import { Nft } from './types'

const Nfts: Nft[] = [
  {
    index: 0,
    name: 'Obie Dobo',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/0.png',
    uri: '/0',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003282',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 682,
    },
  },
  {
    index: 1,
    name: 'Apeguru',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/1.png',
    uri: '/1',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.004051',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 841,
    },
  },
  {
    index: 2,
    name: 'Apetastic',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/2.png',
    uri: '/2',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.003029',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 604,
    },
  },
  {
    index: 3,
    name: 'DKdonkiekong',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/3.png',
    uri: '/3',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Sweat Band',
      rarityScore: '0.006531',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 923,
    },
  },
  {
    index: 4,
    name: 'HarambeNakamoto',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/4.png',
    uri: '/4',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.000457',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 129,
    },
  },
  {
    index: 5,
    name: 'Smart Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/5.png',
    uri: '/5',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003434',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 718,
    },
  },
  {
    index: 6,
    name: 'We are gonna make it',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/6.png',
    uri: '/6',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.003333',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 697,
    },
  },
  {
    index: 7,
    name: 'Ape Daddy',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/7.png',
    uri: '/7',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.004878',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 898,
    },
  },
  {
    index: 8,
    name: 'Ape Lord The First',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/8.png',
    uri: '/8',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003064',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 626,
    },
  },
  {
    index: 9,
    name: 'Diddy Kong',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/9.png',
    uri: '/9',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.000147',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 46,
    },
  },
  {
    index: 10,
    name: 'ApeelingApe',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/10.png',
    uri: '/10',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.003615',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 760,
    },
  },
  {
    index: 11,
    name: 'Forever Chimpin',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/11.png',
    uri: '/11',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.003383',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 708,
    },
  },
  {
    index: 12,
    name: 'Ultimate, Diamond, Mega Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/12.png',
    uri: '/12',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Astronaut',
      rarityScore: '0.002700',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 464,
    },
  },
  {
    index: 13,
    name: 'Elon Musk Rides on A Banana to Mars',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/13.png',
    uri: '/13',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.002489',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 381,
    },
  },
  {
    index: 14,
    name: 'KongKyle',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/14.png',
    uri: '/14',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000870',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 212,
    },
  },
  {
    index: 15,
    name: 'Apegineer',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/15.png',
    uri: '/15',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.003234',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 672,
    },
  },
  {
    index: 16,
    name: 'Banana',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/16.png',
    uri: '/16',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Pale Cowboy',
      rarityScore: '0.000678',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 185,
    },
  },
  {
    index: 17,
    name: 'Early Retirement',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/17.png',
    uri: '/17',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.003851',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 812,
    },
  },
  {
    index: 18,
    name: 'Erwin Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/18.png',
    uri: '/18',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002117',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 286,
    },
  },
  {
    index: 19,
    name: 'ShouldaBeenBatman',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/19.png',
    uri: '/19',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Astronaut',
      rarityScore: '0.002646',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 434,
    },
  },
  {
    index: 20,
    name: '400Unicorns Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/20.png',
    uri: '/20',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.002255',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 312,
    },
  },
  {
    index: 21,
    name: 'Essential Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/21.png',
    uri: '/21',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003629',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 762,
    },
  },
  {
    index: 22,
    name: 'Married Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/22.png',
    uri: '/22',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.003062',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 623,
    },
  },
  {
    index: 23,
    name: 'Cold Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/23.png',
    uri: '/23',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans Red',
      hats: 'Visor',
      rarityScore: '0.000513',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 145,
    },
  },
  {
    index: 24,
    name: 'Everlasting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/24.png',
    uri: '/24',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003978',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 831,
    },
  },
  {
    index: 25,
    name: 'Scornful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/25.png',
    uri: '/25',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.002308',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 330,
    },
  },
  {
    index: 26,
    name: 'Unconscious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/26.png',
    uri: '/26',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Astronaut',
      rarityScore: '0.001844',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 257,
    },
  },
  {
    index: 27,
    name: 'Flickering Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/27.png',
    uri: '/27',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.004817',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 890,
    },
  },
  {
    index: 28,
    name: 'Intent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/28.png',
    uri: '/28',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.003506',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 735,
    },
  },
  {
    index: 29,
    name: '2 Pizzas Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/29.png',
    uri: '/29',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.002405',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 357,
    },
  },
  {
    index: 30,
    name: 'Humble Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/30.png',
    uri: '/30',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002258',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 313,
    },
  },
  {
    index: 31,
    name: 'Questionable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/31.png',
    uri: '/31',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.005174',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 912,
    },
  },
  {
    index: 32,
    name: 'Blank Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/32.png',
    uri: '/32',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Lazer',
      hats: 'Cop',
      rarityScore: '0.001168',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 237,
    },
  },
  {
    index: 33,
    name: 'Official Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/33.png',
    uri: '/33',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.002656',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 438,
    },
  },
  {
    index: 34,
    name: 'Fruitful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/34.png',
    uri: '/34',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.003338',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 700,
    },
  },
  {
    index: 35,
    name: 'Both Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/35.png',
    uri: '/35',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.002581',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 408,
    },
  },
  {
    index: 36,
    name: 'Bowed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/36.png',
    uri: '/36',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Visor',
      rarityScore: '0.000128',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 28,
    },
  },
  {
    index: 37,
    name: 'Paltry Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/37.png',
    uri: '/37',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.002428',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 366,
    },
  },
  {
    index: 38,
    name: 'Eminent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/38.png',
    uri: '/38',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.002731',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 479,
    },
  },
  {
    index: 39,
    name: 'Enchanting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/39.png',
    uri: '/39',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.004092',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 846,
    },
  },
  {
    index: 40,
    name: 'Thorny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/40.png',
    uri: '/40',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002615',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 422,
    },
  },
  {
    index: 41,
    name: 'Apt Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/41.png',
    uri: '/41',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002746',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 486,
    },
  },
  {
    index: 42,
    name: 'Grand Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/42.png',
    uri: '/42',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.002823',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 516,
    },
  },
  {
    index: 43,
    name: 'Hateful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/43.png',
    uri: '/43',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002445',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 371,
    },
  },
  {
    index: 44,
    name: 'Lighthearted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/44.png',
    uri: '/44',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002141',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 289,
    },
  },
  {
    index: 45,
    name: 'Bite-Sized Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/45.png',
    uri: '/45',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.009741',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 971,
    },
  },
  {
    index: 46,
    name: 'Unnatural Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/46.png',
    uri: '/46',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.003975',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 830,
    },
  },
  {
    index: 47,
    name: 'Old-Fashioned Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/47.png',
    uri: '/47',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Sweat Band',
      rarityScore: '0.002188',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 297,
    },
  },
  {
    index: 48,
    name: 'Precious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/48.png',
    uri: '/48',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.000141',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 38,
    },
  },
  {
    index: 49,
    name: 'Trim Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/49.png',
    uri: '/49',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002931',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 570,
    },
  },
  {
    index: 50,
    name: 'Frugal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/50.png',
    uri: '/50',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.001853',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 258,
    },
  },
  {
    index: 51,
    name: 'Lawful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/51.png',
    uri: '/51',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.002824',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 518,
    },
  },
  {
    index: 52,
    name: 'Cultivated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/52.png',
    uri: '/52',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.012782',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 996,
    },
  },
  {
    index: 53,
    name: 'Austere Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/53.png',
    uri: '/53',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.004533',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 879,
    },
  },
  {
    index: 54,
    name: 'Icy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/54.png',
    uri: '/54',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.004820',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 891,
    },
  },
  {
    index: 55,
    name: 'Cooked Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/55.png',
    uri: '/55',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans Red',
      hats: 'Astronaut',
      rarityScore: '0.000600',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 166,
    },
  },
  {
    index: 56,
    name: 'Loathsome Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/56.png',
    uri: '/56',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002849',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 533,
    },
  },
  {
    index: 57,
    name: 'Idle Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/57.png',
    uri: '/57',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.000753',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 198,
    },
  },
  {
    index: 58,
    name: 'Unfortunate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/58.png',
    uri: '/58',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.000145',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 41,
    },
  },
  {
    index: 59,
    name: 'Esteemed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/59.png',
    uri: '/59',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.002426',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 364,
    },
  },
  {
    index: 60,
    name: 'Acclaimed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/60.png',
    uri: '/60',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Sweat Band',
      rarityScore: '0.000141',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 39,
    },
  },
  {
    index: 61,
    name: 'Monumental Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/61.png',
    uri: '/61',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.003164',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 654,
    },
  },
  {
    index: 62,
    name: 'Icebergy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/62.png',
    uri: '/62',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.003411',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 715,
    },
  },
  {
    index: 63,
    name: 'Scented Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/63.png',
    uri: '/63',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.000516',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 146,
    },
  },
  {
    index: 64,
    name: 'Mediocre Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/64.png',
    uri: '/64',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Red',
      hats: 'Astronaut',
      rarityScore: '0.000357',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 84,
    },
  },
  {
    index: 65,
    name: 'Optimistic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/65.png',
    uri: '/65',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002279',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 321,
    },
  },
  {
    index: 66,
    name: 'Organic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/66.png',
    uri: '/66',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Banana Hair',
      rarityScore: '0.001007',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 228,
    },
  },
  {
    index: 67,
    name: 'Lazy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/67.png',
    uri: '/67',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.003778',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 795,
    },
  },
  {
    index: 68,
    name: 'Bewitched Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/68.png',
    uri: '/68',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.002745',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 484,
    },
  },
  {
    index: 69,
    name: 'Decisive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/69.png',
    uri: '/69',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003130',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 639,
    },
  },
  {
    index: 70,
    name: 'Obedient Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/70.png',
    uri: '/70',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Red Tassle',
      rarityScore: '0.001309',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 242,
    },
  },
  {
    index: 71,
    name: 'Cryptobethany Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/71.png',
    uri: '/71',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.003133',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 640,
    },
  },
  {
    index: 72,
    name: 'Inferior Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/72.png',
    uri: '/72',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.003102',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 634,
    },
  },
  {
    index: 73,
    name: 'Nonstop Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/73.png',
    uri: '/73',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.000362',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 86,
    },
  },
  {
    index: 74,
    name: 'Accurate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/74.png',
    uri: '/74',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Avatar',
      rarityScore: '0.000687',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 188,
    },
  },
  {
    index: 75,
    name: 'Near Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/75.png',
    uri: '/75',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.003865',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 814,
    },
  },
  {
    index: 76,
    name: 'Embellished Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/76.png',
    uri: '/76',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.001983',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 270,
    },
  },
  {
    index: 77,
    name: 'Criminal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/77.png',
    uri: '/77',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Pale Cowboy',
      rarityScore: '0.000923',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 224,
    },
  },
  {
    index: 78,
    name: 'Necessary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/78.png',
    uri: '/78',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Visor',
      rarityScore: '0.002357',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 344,
    },
  },
  {
    index: 79,
    name: 'Mature Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/79.png',
    uri: '/79',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.011326',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 989,
    },
  },
  {
    index: 80,
    name: 'Enchanted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/80.png',
    uri: '/80',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.000351',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 83,
    },
  },
  {
    index: 81,
    name: 'Insistent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/81.png',
    uri: '/81',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002809',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 512,
    },
  },
  {
    index: 82,
    name: 'Tubby Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/82.png',
    uri: '/82',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.000428',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 117,
    },
  },
  {
    index: 83,
    name: 'Tough Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/83.png',
    uri: '/83',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.000404',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 104,
    },
  },
  {
    index: 84,
    name: 'Prize Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/84.png',
    uri: '/84',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Avatar',
      rarityScore: '0.000534',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 152,
    },
  },
  {
    index: 85,
    name: 'Quirky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/85.png',
    uri: '/85',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.008001',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 950,
    },
  },
  {
    index: 86,
    name: 'Linear Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/86.png',
    uri: '/86',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.003819',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 804,
    },
  },
  {
    index: 87,
    name: 'Decent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/87.png',
    uri: '/87',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003511',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 736,
    },
  },
  {
    index: 88,
    name: 'Good-Natured Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/88.png',
    uri: '/88',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.000138',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 34,
    },
  },
  {
    index: 89,
    name: 'Jumpy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/89.png',
    uri: '/89',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Visor',
      rarityScore: '0.002408',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 358,
    },
  },
  {
    index: 90,
    name: 'Giving Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/90.png',
    uri: '/90',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002969',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 587,
    },
  },
  {
    index: 91,
    name: 'Loyal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/91.png',
    uri: '/91',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Pale Cowboy',
      rarityScore: '0.000622',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 171,
    },
  },
  {
    index: 92,
    name: 'Fickle Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/92.png',
    uri: '/92',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.000504',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 143,
    },
  },
  {
    index: 93,
    name: 'Dearest Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/93.png',
    uri: '/93',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.002488',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 380,
    },
  },
  {
    index: 94,
    name: 'Quarrelsome Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/94.png',
    uri: '/94',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.002355',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 343,
    },
  },
  {
    index: 95,
    name: 'Abandoned Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/95.png',
    uri: '/95',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.002646',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 435,
    },
  },
  {
    index: 96,
    name: 'Uncomfortable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/96.png',
    uri: '/96',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.004394',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 871,
    },
  },
  {
    index: 97,
    name: 'Handsome Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/97.png',
    uri: '/97',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Sweat Band',
      rarityScore: '0.007255',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 930,
    },
  },
  {
    index: 98,
    name: 'Naive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/98.png',
    uri: '/98',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.003253',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 675,
    },
  },
  {
    index: 99,
    name: 'Liquid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/99.png',
    uri: '/99',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.000129',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 29,
    },
  },
  {
    index: 100,
    name: 'Crafty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/100.png',
    uri: '/100',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.002985',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 594,
    },
  },
  {
    index: 101,
    name: 'Energetic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/101.png',
    uri: '/101',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.002668',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 447,
    },
  },
  {
    index: 102,
    name: 'Naughty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/102.png',
    uri: '/102',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Lazer',
      hats: 'Red Tassle',
      rarityScore: '0.000953',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 225,
    },
  },
  {
    index: 103,
    name: 'Edible Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/103.png',
    uri: '/103',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Visor',
      rarityScore: '0.002537',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 396,
    },
  },
  {
    index: 104,
    name: 'Queasy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/104.png',
    uri: '/104',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003096',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 632,
    },
  },
  {
    index: 105,
    name: 'Made-Up Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/105.png',
    uri: '/105',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002858',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 536,
    },
  },
  {
    index: 106,
    name: 'Crypto Ogle Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/106.png',
    uri: '/106',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002970',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 588,
    },
  },
  {
    index: 107,
    name: 'Barneytheboi Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/107.png',
    uri: '/107',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.003123',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 638,
    },
  },
  {
    index: 108,
    name: 'Male Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/108.png',
    uri: '/108',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.003142',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 644,
    },
  },
  {
    index: 109,
    name: 'Frosty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/109.png',
    uri: '/109',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.002554',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 401,
    },
  },
  {
    index: 110,
    name: 'Formal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/110.png',
    uri: '/110',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002909',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 551,
    },
  },
  {
    index: 111,
    name: 'Thrifty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/111.png',
    uri: '/111',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.002708',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 472,
    },
  },
  {
    index: 112,
    name: 'Fancy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/112.png',
    uri: '/112',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.005108',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 909,
    },
  },
  {
    index: 113,
    name: 'Blue Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/113.png',
    uri: '/113',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.003744',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 787,
    },
  },
  {
    index: 114,
    name: 'Super Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/114.png',
    uri: '/114',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.003318',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 696,
    },
  },
  {
    index: 115,
    name: 'Delayed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/115.png',
    uri: '/115',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.002929',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 568,
    },
  },
  {
    index: 116,
    name: 'Anxious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/116.png',
    uri: '/116',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.003937',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 824,
    },
  },
  {
    index: 117,
    name: 'Ignorant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/117.png',
    uri: '/117',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.002894',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 549,
    },
  },
  {
    index: 118,
    name: 'Demanding Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/118.png',
    uri: '/118',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.002512',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 388,
    },
  },
  {
    index: 119,
    name: 'Lumpy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/119.png',
    uri: '/119',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.002192',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 299,
    },
  },
  {
    index: 120,
    name: 'Looposhi Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/120.png',
    uri: '/120',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.004091',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 845,
    },
  },
  {
    index: 121,
    name: 'Critical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/121.png',
    uri: '/121',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Visor',
      rarityScore: '0.000138',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 35,
    },
  },
  {
    index: 122,
    name: 'Murky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/122.png',
    uri: '/122',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.003334',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 698,
    },
  },
  {
    index: 123,
    name: 'Elegant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/123.png',
    uri: '/123',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.003638',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 765,
    },
  },
  {
    index: 124,
    name: 'Lively Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/124.png',
    uri: '/124',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002823',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 517,
    },
  },
  {
    index: 125,
    name: 'Fussy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/125.png',
    uri: '/125',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Astronaut',
      rarityScore: '0.000369',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 89,
    },
  },
  {
    index: 126,
    name: 'Definite Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/126.png',
    uri: '/126',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.002713',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 473,
    },
  },
  {
    index: 127,
    name: 'Costly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/127.png',
    uri: '/127',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Brown Hair',
      rarityScore: '0.004180',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 856,
    },
  },
  {
    index: 128,
    name: 'Lanky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/128.png',
    uri: '/128',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002382',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 349,
    },
  },
  {
    index: 129,
    name: 'Awful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/129.png',
    uri: '/129',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.003185',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 660,
    },
  },
  {
    index: 130,
    name: 'Enlightened Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/130.png',
    uri: '/130',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.005284',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 913,
    },
  },
  {
    index: 131,
    name: 'Sunny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/131.png',
    uri: '/131',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.002643',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 431,
    },
  },
  {
    index: 132,
    name: 'Moonoverlord Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/132.png',
    uri: '/132',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.003722',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 781,
    },
  },
  {
    index: 133,
    name: 'Complete Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/133.png',
    uri: '/133',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.004876',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 897,
    },
  },
  {
    index: 134,
    name: 'Heavenly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/134.png',
    uri: '/134',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003036',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 607,
    },
  },
  {
    index: 135,
    name: 'Firsthand Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/135.png',
    uri: '/135',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.000331',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 72,
    },
  },
  {
    index: 136,
    name: 'Noxious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/136.png',
    uri: '/136',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.000145',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 42,
    },
  },
  {
    index: 137,
    name: 'Iamnomad Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/137.png',
    uri: '/137',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Earings',
      mouths: 'Open Smile',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.000023',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 4,
    },
  },
  {
    index: 138,
    name: 'Gentle Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/138.png',
    uri: '/138',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.004387',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 870,
    },
  },
  {
    index: 139,
    name: 'Crypto Bobby Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/139.png',
    uri: '/139',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.002397',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 355,
    },
  },
  {
    index: 140,
    name: 'Electric Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/140.png',
    uri: '/140',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Avatar',
      rarityScore: '0.000510',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 144,
    },
  },
  {
    index: 141,
    name: 'Grown Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/141.png',
    uri: '/141',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.002249',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 310,
    },
  },
  {
    index: 142,
    name: 'Extra-Small Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/142.png',
    uri: '/142',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.000912',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 222,
    },
  },
  {
    index: 143,
    name: 'Unequaled Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/143.png',
    uri: '/143',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.001218',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 238,
    },
  },
  {
    index: 144,
    name: 'Needacoin Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/144.png',
    uri: '/144',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.002632',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 429,
    },
  },
  {
    index: 145,
    name: 'Revolving Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/145.png',
    uri: '/145',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.008084',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 951,
    },
  },
  {
    index: 146,
    name: 'Tiny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/146.png',
    uri: '/146',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Lazer',
      hats: 'Third Eye',
      rarityScore: '0.000175',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 55,
    },
  },
  {
    index: 147,
    name: 'Prudent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/147.png',
    uri: '/147',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002631',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 428,
    },
  },
  {
    index: 148,
    name: 'Lovable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/148.png',
    uri: '/148',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.004221',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 860,
    },
  },
  {
    index: 149,
    name: 'Kaleidoscopic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/149.png',
    uri: '/149',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.000337',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 78,
    },
  },
  {
    index: 150,
    name: 'Astonishing Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/150.png',
    uri: '/150',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002004',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 272,
    },
  },
  {
    index: 151,
    name: 'Troubled Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/151.png',
    uri: '/151',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans Red',
      hats: 'Red Tassle',
      rarityScore: '0.000619',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 169,
    },
  },
  {
    index: 152,
    name: 'Present Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/152.png',
    uri: '/152',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Lazer',
      hats: 'Red Tassle',
      rarityScore: '0.000747',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 197,
    },
  },
  {
    index: 153,
    name: 'Close Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/153.png',
    uri: '/153',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Brown Hair',
      rarityScore: '0.001144',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 235,
    },
  },
  {
    index: 154,
    name: 'Muted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/154.png',
    uri: '/154',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Pale Cowboy',
      rarityScore: '0.000496',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 141,
    },
  },
  {
    index: 155,
    name: 'Intelligent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/155.png',
    uri: '/155',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Cop',
      rarityScore: '0.003787',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 796,
    },
  },
  {
    index: 156,
    name: 'Idealistic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/156.png',
    uri: '/156',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.000480',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 135,
    },
  },
  {
    index: 157,
    name: 'Dual Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/157.png',
    uri: '/157',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.002679',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 454,
    },
  },
  {
    index: 158,
    name: 'Orange Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/158.png',
    uri: '/158',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.000690',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 189,
    },
  },
  {
    index: 159,
    name: 'Sentimental Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/159.png',
    uri: '/159',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.002657',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 439,
    },
  },
  {
    index: 160,
    name: 'Actual Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/160.png',
    uri: '/160',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003689',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 774,
    },
  },
  {
    index: 161,
    name: 'Skeletal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/161.png',
    uri: '/161',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.000111',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 24,
    },
  },
  {
    index: 162,
    name: 'Insignificant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/162.png',
    uri: '/162',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.002803',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 507,
    },
  },
  {
    index: 163,
    name: 'Impartial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/163.png',
    uri: '/163',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.002968',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 586,
    },
  },
  {
    index: 164,
    name: 'Magnificent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/164.png',
    uri: '/164',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.000644',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 177,
    },
  },
  {
    index: 165,
    name: 'Skinny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/165.png',
    uri: '/165',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.003505',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 734,
    },
  },
  {
    index: 166,
    name: 'Serious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/166.png',
    uri: '/166',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003723',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 782,
    },
  },
  {
    index: 167,
    name: 'Forked Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/167.png',
    uri: '/167',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Brown Hair',
      rarityScore: '0.000077',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 14,
    },
  },
  {
    index: 168,
    name: 'Aromatic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/168.png',
    uri: '/168',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.003308',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 691,
    },
  },
  {
    index: 169,
    name: 'Practical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/169.png',
    uri: '/169',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.004521',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 878,
    },
  },
  {
    index: 170,
    name: 'Growling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/170.png',
    uri: '/170',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.007630',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 938,
    },
  },
  {
    index: 171,
    name: 'Lonely Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/171.png',
    uri: '/171',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Lazer',
      hats: 'Astronaut',
      rarityScore: '0.000833',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 208,
    },
  },
  {
    index: 172,
    name: 'Elated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/172.png',
    uri: '/172',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000055',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 10,
    },
  },
  {
    index: 173,
    name: 'Huge Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/173.png',
    uri: '/173',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002175',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 293,
    },
  },
  {
    index: 174,
    name: 'Real Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/174.png',
    uri: '/174',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.002949',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 579,
    },
  },
  {
    index: 175,
    name: 'Positive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/175.png',
    uri: '/175',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.003021',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 602,
    },
  },
  {
    index: 176,
    name: 'Informal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/176.png',
    uri: '/176',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.000145',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 43,
    },
  },
  {
    index: 177,
    name: 'Fixed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/177.png',
    uri: '/177',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.003572',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 748,
    },
  },
  {
    index: 178,
    name: 'Grave Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/178.png',
    uri: '/178',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.000381',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 97,
    },
  },
  {
    index: 179,
    name: 'Lopsided Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/179.png',
    uri: '/179',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.009422',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 965,
    },
  },
  {
    index: 180,
    name: 'Miserable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/180.png',
    uri: '/180',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Visor',
      rarityScore: '0.002418',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 360,
    },
  },
  {
    index: 181,
    name: 'Likable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/181.png',
    uri: '/181',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Pale Cowboy',
      rarityScore: '0.000030',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 6,
    },
  },
  {
    index: 182,
    name: 'Threadbare Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/182.png',
    uri: '/182',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.000081',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 15,
    },
  },
  {
    index: 183,
    name: 'Nimble Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/183.png',
    uri: '/183',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.002696',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 460,
    },
  },
  {
    index: 184,
    name: 'Responsible Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/184.png',
    uri: '/184',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.000383',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 98,
    },
  },
  {
    index: 185,
    name: 'Frivolous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/185.png',
    uri: '/185',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.004024',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 837,
    },
  },
  {
    index: 186,
    name: 'Frequent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/186.png',
    uri: '/186',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Dark Cowboy',
      rarityScore: '0.004083',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 844,
    },
  },
  {
    index: 187,
    name: 'Crooked Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/187.png',
    uri: '/187',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.002831',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 521,
    },
  },
  {
    index: 188,
    name: 'Composed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/188.png',
    uri: '/188',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002749',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 489,
    },
  },
  {
    index: 189,
    name: 'Mt. Gox Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/189.png',
    uri: '/189',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.001464',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 246,
    },
  },
  {
    index: 190,
    name: 'Self-Assured Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/190.png',
    uri: '/190',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003309',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 692,
    },
  },
  {
    index: 191,
    name: 'Scarce Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/191.png',
    uri: '/191',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.000380',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 96,
    },
  },
  {
    index: 192,
    name: 'Hideous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/192.png',
    uri: '/192',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.002909',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 552,
    },
  },
  {
    index: 193,
    name: 'Idolized Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/193.png',
    uri: '/193',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.002704',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 467,
    },
  },
  {
    index: 194,
    name: 'Serpentine Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/194.png',
    uri: '/194',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.000349',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 82,
    },
  },
  {
    index: 195,
    name: 'Empty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/195.png',
    uri: '/195',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.004172',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 855,
    },
  },
  {
    index: 196,
    name: 'Fabulous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/196.png',
    uri: '/196',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.002801',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 504,
    },
  },
  {
    index: 197,
    name: 'Even Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/197.png',
    uri: '/197',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003705',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 779,
    },
  },
  {
    index: 198,
    name: 'Fearful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/198.png',
    uri: '/198',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004170',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 853,
    },
  },
  {
    index: 199,
    name: 'Frightening Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/199.png',
    uri: '/199',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.002465',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 376,
    },
  },
  {
    index: 200,
    name: 'Giddy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/200.png',
    uri: '/200',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.004447',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 875,
    },
  },
  {
    index: 201,
    name: 'Unaware Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/201.png',
    uri: '/201',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Visor',
      rarityScore: '0.002063',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 277,
    },
  },
  {
    index: 202,
    name: 'Creepy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/202.png',
    uri: '/202',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'VR Goggles',
      hats: 'Avatar',
      rarityScore: '0.000089',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 19,
    },
  },
  {
    index: 203,
    name: 'Michael Saylor Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/203.png',
    uri: '/203',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.002835',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 525,
    },
  },
  {
    index: 204,
    name: 'Genesis Block Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/204.png',
    uri: '/204',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Cop',
      rarityScore: '0.002575',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 406,
    },
  },
  {
    index: 205,
    name: 'Noisy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/205.png',
    uri: '/205',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Pale Cowboy',
      rarityScore: '0.002329',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 337,
    },
  },
  {
    index: 206,
    name: 'Emotional Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/206.png',
    uri: '/206',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003474',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 725,
    },
  },
  {
    index: 207,
    name: 'Separate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/207.png',
    uri: '/207',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.002800',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 503,
    },
  },
  {
    index: 208,
    name: 'Ledgerstatus Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/208.png',
    uri: '/208',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002836',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 526,
    },
  },
  {
    index: 209,
    name: 'Chief Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/209.png',
    uri: '/209',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.002963',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 584,
    },
  },
  {
    index: 210,
    name: 'Mellow Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/210.png',
    uri: '/210',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.003171',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 656,
    },
  },
  {
    index: 211,
    name: 'Legal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/211.png',
    uri: '/211',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.002424',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 363,
    },
  },
  {
    index: 212,
    name: 'Irresponsible Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/212.png',
    uri: '/212',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Banana Hair',
      rarityScore: '0.003052',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 618,
    },
  },
  {
    index: 213,
    name: 'Granular Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/213.png',
    uri: '/213',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002590',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 417,
    },
  },
  {
    index: 214,
    name: 'Those Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/214.png',
    uri: '/214',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000630',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 172,
    },
  },
  {
    index: 215,
    name: 'Pungent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/215.png',
    uri: '/215',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.003519',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 739,
    },
  },
  {
    index: 216,
    name: 'Joint Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/216.png',
    uri: '/216',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004302',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 866,
    },
  },
  {
    index: 217,
    name: 'Peppery Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/217.png',
    uri: '/217',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002490',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 382,
    },
  },
  {
    index: 218,
    name: 'Blue Kirby Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/218.png',
    uri: '/218',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002705',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 469,
    },
  },
  {
    index: 219,
    name: 'Definitive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/219.png',
    uri: '/219',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.010775',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 983,
    },
  },
  {
    index: 220,
    name: 'Unhealthy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/220.png',
    uri: '/220',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Sweat Band',
      rarityScore: '0.002685',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 459,
    },
  },
  {
    index: 221,
    name: 'Right Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/221.png',
    uri: '/221',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002872',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 542,
    },
  },
  {
    index: 222,
    name: 'Hard Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/222.png',
    uri: '/222',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.002676',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 451,
    },
  },
  {
    index: 223,
    name: 'Hidden Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/223.png',
    uri: '/223',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans Red',
      hats: 'Banana Hair',
      rarityScore: '0.000556',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 157,
    },
  },
  {
    index: 224,
    name: 'Dear Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/224.png',
    uri: '/224',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Astronaut',
      rarityScore: '0.001976',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 269,
    },
  },
  {
    index: 225,
    name: 'High Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/225.png',
    uri: '/225',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.000053',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 9,
    },
  },
  {
    index: 226,
    name: 'Hopeful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/226.png',
    uri: '/226',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Lazer',
      hats: 'Red Tassle',
      rarityScore: '0.000908',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 221,
    },
  },
  {
    index: 227,
    name: 'Peter Schiff Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/227.png',
    uri: '/227',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.010844',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 984,
    },
  },
  {
    index: 228,
    name: 'Deep Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/228.png',
    uri: '/228',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.009543',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 966,
    },
  },
  {
    index: 229,
    name: 'Grubby Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/229.png',
    uri: '/229',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Banana Hair',
      rarityScore: '0.002909',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 553,
    },
  },
  {
    index: 230,
    name: 'Beeple Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/230.png',
    uri: '/230',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002779',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 496,
    },
  },
  {
    index: 231,
    name: 'Drafty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/231.png',
    uri: '/231',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Avatar',
      rarityScore: '0.000574',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 160,
    },
  },
  {
    index: 232,
    name: 'Dark Pill Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/232.png',
    uri: '/232',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Red Tassle',
      rarityScore: '0.002972',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 589,
    },
  },
  {
    index: 233,
    name: 'Biodegradable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/233.png',
    uri: '/233',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.000188',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 59,
    },
  },
  {
    index: 234,
    name: 'Unkempt Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/234.png',
    uri: '/234',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.004077',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 842,
    },
  },
  {
    index: 235,
    name: 'Monstrous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/235.png',
    uri: '/235',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.004755',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 885,
    },
  },
  {
    index: 236,
    name: 'Kindhearted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/236.png',
    uri: '/236',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003608',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 757,
    },
  },
  {
    index: 237,
    name: 'Jubilant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/237.png',
    uri: '/237',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003775',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 794,
    },
  },
  {
    index: 238,
    name: 'Pointless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/238.png',
    uri: '/238',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.000547',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 156,
    },
  },
  {
    index: 239,
    name: 'Frail Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/239.png',
    uri: '/239',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Sweat Band',
      rarityScore: '0.002550',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 399,
    },
  },
  {
    index: 240,
    name: 'Kind Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/240.png',
    uri: '/240',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.007211',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 929,
    },
  },
  {
    index: 241,
    name: 'Better Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/241.png',
    uri: '/241',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.002803',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 508,
    },
  },
  {
    index: 242,
    name: 'Gigantic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/242.png',
    uri: '/242',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.003215',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 666,
    },
  },
  {
    index: 243,
    name: 'Total Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/243.png',
    uri: '/243',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002886',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 547,
    },
  },
  {
    index: 244,
    name: 'Loose Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/244.png',
    uri: '/244',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.004394',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 872,
    },
  },
  {
    index: 245,
    name: 'Darling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/245.png',
    uri: '/245',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.000113',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 25,
    },
  },
  {
    index: 246,
    name: 'Flawless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/246.png',
    uri: '/246',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.010331',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 977,
    },
  },
  {
    index: 247,
    name: 'Jagged Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/247.png',
    uri: '/247',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003136',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 642,
    },
  },
  {
    index: 248,
    name: 'Loomdart Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/248.png',
    uri: '/248',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.002806',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 510,
    },
  },
  {
    index: 249,
    name: 'Notsofast Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/249.png',
    uri: '/249',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.003839',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 809,
    },
  },
  {
    index: 250,
    name: 'Dopey Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/250.png',
    uri: '/250',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.012270',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 993,
    },
  },
  {
    index: 251,
    name: 'Familiar Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/251.png',
    uri: '/251',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.000024',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 5,
    },
  },
  {
    index: 252,
    name: 'Half Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/252.png',
    uri: '/252',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.003068',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 628,
    },
  },
  {
    index: 253,
    name: 'Dental Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/253.png',
    uri: '/253',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Pale Cowboy',
      rarityScore: '0.000832',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 207,
    },
  },
  {
    index: 254,
    name: 'Far-Off Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/254.png',
    uri: '/254',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Lazer',
      hats: 'Visor',
      rarityScore: '0.000903',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 219,
    },
  },
  {
    index: 255,
    name: 'Devoted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/255.png',
    uri: '/255',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.002853',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 534,
    },
  },
  {
    index: 256,
    name: 'Portly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/256.png',
    uri: '/256',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.002806',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 511,
    },
  },
  {
    index: 257,
    name: 'Delicious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/257.png',
    uri: '/257',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003845',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 811,
    },
  },
  {
    index: 258,
    name: 'Thoughtful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/258.png',
    uri: '/258',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.004753',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 884,
    },
  },
  {
    index: 259,
    name: 'Interesting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/259.png',
    uri: '/259',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.003149',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 648,
    },
  },
  {
    index: 260,
    name: 'Faraway Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/260.png',
    uri: '/260',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.004093',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 847,
    },
  },
  {
    index: 261,
    name: 'Mountainous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/261.png',
    uri: '/261',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.002720',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 475,
    },
  },
  {
    index: 262,
    name: 'Proud Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/262.png',
    uri: '/262',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.002230',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 303,
    },
  },
  {
    index: 263,
    name: 'Rich Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/263.png',
    uri: '/263',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.000174',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 54,
    },
  },
  {
    index: 264,
    name: 'Quick-Witted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/264.png',
    uri: '/264',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.004842',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 894,
    },
  },
  {
    index: 265,
    name: 'Misty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/265.png',
    uri: '/265',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002199',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 301,
    },
  },
  {
    index: 266,
    name: 'Far-Flung Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/266.png',
    uri: '/266',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004825',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 892,
    },
  },
  {
    index: 267,
    name: 'General Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/267.png',
    uri: '/267',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.004917',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 900,
    },
  },
  {
    index: 268,
    name: 'Glass Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/268.png',
    uri: '/268',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Lazer',
      hats: 'Dark Cowboy',
      rarityScore: '0.000922',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 223,
    },
  },
  {
    index: 269,
    name: 'Defiant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/269.png',
    uri: '/269',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans Red',
      hats: 'Visor',
      rarityScore: '0.000375',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 91,
    },
  },
  {
    index: 270,
    name: 'Vacant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/270.png',
    uri: '/270',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.003707',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 780,
    },
  },
  {
    index: 271,
    name: 'Angry Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/271.png',
    uri: '/271',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002493',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 384,
    },
  },
  {
    index: 272,
    name: 'Golden Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/272.png',
    uri: '/272',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.000131',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 32,
    },
  },
  {
    index: 273,
    name: 'Cryptosqueeze Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/273.png',
    uri: '/273',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Brown Hair',
      rarityScore: '0.003003',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 600,
    },
  },
  {
    index: 274,
    name: 'Cuddly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/274.png',
    uri: '/274',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004511',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 877,
    },
  },
  {
    index: 275,
    name: 'Grizzled Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/275.png',
    uri: '/275',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.000072',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 13,
    },
  },
  {
    index: 276,
    name: 'Legitimate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/276.png',
    uri: '/276',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002882',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 546,
    },
  },
  {
    index: 277,
    name: 'Average Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/277.png',
    uri: '/277',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Dark Cowboy',
      rarityScore: '0.004005',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 835,
    },
  },
  {
    index: 278,
    name: 'Clueless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/278.png',
    uri: '/278',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.003386',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 709,
    },
  },
  {
    index: 279,
    name: 'Scared Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/279.png',
    uri: '/279',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Avatar',
      rarityScore: '0.001032',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 231,
    },
  },
  {
    index: 280,
    name: 'Luxurious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/280.png',
    uri: '/280',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.003062',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 624,
    },
  },
  {
    index: 281,
    name: 'Few Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/281.png',
    uri: '/281',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003180',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 658,
    },
  },
  {
    index: 282,
    name: 'Limping Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/282.png',
    uri: '/282',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.008871',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 960,
    },
  },
  {
    index: 283,
    name: 'Irritating Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/283.png',
    uri: '/283',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.000483',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 137,
    },
  },
  {
    index: 284,
    name: 'Sbf Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/284.png',
    uri: '/284',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.009639',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 969,
    },
  },
  {
    index: 285,
    name: 'Enormous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/285.png',
    uri: '/285',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.003630',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 763,
    },
  },
  {
    index: 286,
    name: 'Attractive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/286.png',
    uri: '/286',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.002697',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 462,
    },
  },
  {
    index: 287,
    name: 'Cryptomocho Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/287.png',
    uri: '/287',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.000425',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 115,
    },
  },
  {
    index: 288,
    name: 'Bony Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/288.png',
    uri: '/288',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.007761',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 941,
    },
  },
  {
    index: 289,
    name: 'Admirable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/289.png',
    uri: '/289',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003408',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 713,
    },
  },
  {
    index: 290,
    name: 'Useful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/290.png',
    uri: '/290',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.003120',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 636,
    },
  },
  {
    index: 291,
    name: 'Elementary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/291.png',
    uri: '/291',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.002950',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 580,
    },
  },
  {
    index: 292,
    name: 'Grounded Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/292.png',
    uri: '/292',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.003283',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 683,
    },
  },
  {
    index: 293,
    name: 'Another Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/293.png',
    uri: '/293',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.007860',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 945,
    },
  },
  {
    index: 294,
    name: 'Uncommon Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/294.png',
    uri: '/294',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003971',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 829,
    },
  },
  {
    index: 295,
    name: 'Klutzy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/295.png',
    uri: '/295',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.004331',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 867,
    },
  },
  {
    index: 296,
    name: 'Brave Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/296.png',
    uri: '/296',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.000616',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 168,
    },
  },
  {
    index: 297,
    name: 'Humming Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/297.png',
    uri: '/297',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Brown Hair',
      rarityScore: '0.001493',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 247,
    },
  },
  {
    index: 298,
    name: 'Unknown Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/298.png',
    uri: '/298',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.000142',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 40,
    },
  },
  {
    index: 299,
    name: 'Blond Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/299.png',
    uri: '/299',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.002805',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 509,
    },
  },
  {
    index: 300,
    name: 'Sure-Footed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/300.png',
    uri: '/300',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.002841',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 530,
    },
  },
  {
    index: 301,
    name: 'Bitter Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/301.png',
    uri: '/301',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.002596',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 418,
    },
  },
  {
    index: 302,
    name: 'Internal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/302.png',
    uri: '/302',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003546',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 745,
    },
  },
  {
    index: 303,
    name: 'Glum Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/303.png',
    uri: '/303',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.000334',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 76,
    },
  },
  {
    index: 304,
    name: 'Ashamed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/304.png',
    uri: '/304',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.003223',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 668,
    },
  },
  {
    index: 305,
    name: 'Gleaming Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/305.png',
    uri: '/305',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Pale Cowboy',
      rarityScore: '0.001075',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 233,
    },
  },
  {
    index: 306,
    name: 'The Crypto Lark Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/306.png',
    uri: '/306',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.003289',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 687,
    },
  },
  {
    index: 307,
    name: 'Black-And-White Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/307.png',
    uri: '/307',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.002092',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 283,
    },
  },
  {
    index: 308,
    name: 'Clean Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/308.png',
    uri: '/308',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.002981',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 592,
    },
  },
  {
    index: 309,
    name: 'Lavish Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/309.png',
    uri: '/309',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.002589',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 416,
    },
  },
  {
    index: 310,
    name: 'Treasured Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/310.png',
    uri: '/310',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.003798',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 802,
    },
  },
  {
    index: 311,
    name: 'Erratic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/311.png',
    uri: '/311',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.001866',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 259,
    },
  },
  {
    index: 312,
    name: 'Potable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/312.png',
    uri: '/312',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.003040',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 611,
    },
  },
  {
    index: 313,
    name: 'Garyvee Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/313.png',
    uri: '/313',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Astronaut',
      rarityScore: '0.002561',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 403,
    },
  },
  {
    index: 314,
    name: 'Creamy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/314.png',
    uri: '/314',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.002941',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 573,
    },
  },
  {
    index: 315,
    name: 'Bleak Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/315.png',
    uri: '/315',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Red Tassle',
      rarityScore: '0.001933',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 263,
    },
  },
  {
    index: 316,
    name: 'Flat Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/316.png',
    uri: '/316',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.002815',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 513,
    },
  },
  {
    index: 317,
    name: 'Frank Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/317.png',
    uri: '/317',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.011061',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 987,
    },
  },
  {
    index: 318,
    name: 'Authorized Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/318.png',
    uri: '/318',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.000473',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 133,
    },
  },
  {
    index: 319,
    name: 'Failing Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/319.png',
    uri: '/319',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.001778',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 254,
    },
  },
  {
    index: 320,
    name: 'Measly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/320.png',
    uri: '/320',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.003519',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 740,
    },
  },
  {
    index: 321,
    name: 'Ironclad Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/321.png',
    uri: '/321',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002746',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 487,
    },
  },
  {
    index: 322,
    name: 'Ecstatic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/322.png',
    uri: '/322',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.003466',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 722,
    },
  },
  {
    index: 323,
    name: 'Grumpy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/323.png',
    uri: '/323',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Astronaut',
      rarityScore: '0.012560',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 995,
    },
  },
  {
    index: 324,
    name: 'Melodic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/324.png',
    uri: '/324',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Third Eye',
      rarityScore: '0.001018',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 230,
    },
  },
  {
    index: 325,
    name: 'Impeccable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/325.png',
    uri: '/325',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.010736',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 981,
    },
  },
  {
    index: 326,
    name: 'Jihan Wu Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/326.png',
    uri: '/326',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.005804',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 918,
    },
  },
  {
    index: 327,
    name: 'Unique Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/327.png',
    uri: '/327',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.003771',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 793,
    },
  },
  {
    index: 328,
    name: 'Quixotic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/328.png',
    uri: '/328',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.002930',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 569,
    },
  },
  {
    index: 329,
    name: 'Oblong Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/329.png',
    uri: '/329',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004099',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 848,
    },
  },
  {
    index: 330,
    name: 'Ugly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/330.png',
    uri: '/330',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.001446',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 244,
    },
  },
  {
    index: 331,
    name: 'Obese Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/331.png',
    uri: '/331',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.003294',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 689,
    },
  },
  {
    index: 332,
    name: 'Basic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/332.png',
    uri: '/332',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.002802',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 505,
    },
  },
  {
    index: 333,
    name: 'Clear Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/333.png',
    uri: '/333',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.003482',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 728,
    },
  },
  {
    index: 334,
    name: 'Cryptosupplyco Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/334.png',
    uri: '/334',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.000272',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 66,
    },
  },
  {
    index: 335,
    name: 'Probable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/335.png',
    uri: '/335',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003877',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 815,
    },
  },
  {
    index: 336,
    name: 'Orderly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/336.png',
    uri: '/336',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002676',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 452,
    },
  },
  {
    index: 337,
    name: 'Courageous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/337.png',
    uri: '/337',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.003992',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 833,
    },
  },
  {
    index: 338,
    name: 'Absolute Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/338.png',
    uri: '/338',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.008164',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 953,
    },
  },
  {
    index: 339,
    name: 'Dutiful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/339.png',
    uri: '/339',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002497',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 385,
    },
  },
  {
    index: 340,
    name: 'Every Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/340.png',
    uri: '/340',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.002534',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 395,
    },
  },
  {
    index: 341,
    name: 'Unlined Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/341.png',
    uri: '/341',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.002585',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 410,
    },
  },
  {
    index: 342,
    name: 'Full Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/342.png',
    uri: '/342',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.004134',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 849,
    },
  },
  {
    index: 343,
    name: 'Helpless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/343.png',
    uri: '/343',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.003960',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 825,
    },
  },
  {
    index: 344,
    name: 'Fine Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/344.png',
    uri: '/344',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000396',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 100,
    },
  },
  {
    index: 345,
    name: 'Mean Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/345.png',
    uri: '/345',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000661',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 182,
    },
  },
  {
    index: 346,
    name: 'Popular Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/346.png',
    uri: '/346',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.002191',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 298,
    },
  },
  {
    index: 347,
    name: 'Blind Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/347.png',
    uri: '/347',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.003046',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 614,
    },
  },
  {
    index: 348,
    name: 'Imperturbable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/348.png',
    uri: '/348',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002555',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 402,
    },
  },
  {
    index: 349,
    name: 'Thorough Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/349.png',
    uri: '/349',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002401',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 356,
    },
  },
  {
    index: 350,
    name: 'Profuse Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/350.png',
    uri: '/350',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.002834',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 524,
    },
  },
  {
    index: 351,
    name: 'Negligible Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/351.png',
    uri: '/351',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.004444',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 874,
    },
  },
  {
    index: 352,
    name: 'Finished Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/352.png',
    uri: '/352',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.002866',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 541,
    },
  },
  {
    index: 353,
    name: 'New Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/353.png',
    uri: '/353',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002918',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 560,
    },
  },
  {
    index: 354,
    name: 'Chubby Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/354.png',
    uri: '/354',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002644',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 433,
    },
  },
  {
    index: 355,
    name: 'Arid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/355.png',
    uri: '/355',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.000379',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 95,
    },
  },
  {
    index: 356,
    name: 'Bland Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/356.png',
    uri: '/356',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.003765',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 789,
    },
  },
  {
    index: 357,
    name: 'Beneficial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/357.png',
    uri: '/357',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Visor',
      rarityScore: '0.003050',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 616,
    },
  },
  {
    index: 358,
    name: 'Gorgeous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/358.png',
    uri: '/358',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.010534',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 979,
    },
  },
  {
    index: 359,
    name: 'Parched Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/359.png',
    uri: '/359',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Pale Cowboy',
      rarityScore: '0.000237',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 64,
    },
  },
  {
    index: 360,
    name: 'Fortunate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/360.png',
    uri: '/360',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.002907',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 550,
    },
  },
  {
    index: 361,
    name: 'Confused Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/361.png',
    uri: '/361',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.003426',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 717,
    },
  },
  {
    index: 362,
    name: 'Nick Sazbo Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/362.png',
    uri: '/362',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.001942',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 264,
    },
  },
  {
    index: 363,
    name: 'Superior Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/363.png',
    uri: '/363',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003337',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 699,
    },
  },
  {
    index: 364,
    name: 'Pricey Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/364.png',
    uri: '/364',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003632',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 764,
    },
  },
  {
    index: 365,
    name: 'Feline Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/365.png',
    uri: '/365',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.013151',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 999,
    },
  },
  {
    index: 366,
    name: 'Imaginative Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/366.png',
    uri: '/366',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.003770',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 792,
    },
  },
  {
    index: 367,
    name: 'Illustrious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/367.png',
    uri: '/367',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.000498',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 142,
    },
  },
  {
    index: 368,
    name: 'Evergreen Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/368.png',
    uri: '/368',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.003142',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 645,
    },
  },
  {
    index: 369,
    name: 'Negative Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/369.png',
    uri: '/369',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.000181',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 58,
    },
  },
  {
    index: 370,
    name: 'Eager Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/370.png',
    uri: '/370',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.003049',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 615,
    },
  },
  {
    index: 371,
    name: 'Free Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/371.png',
    uri: '/371',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.003170',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 655,
    },
  },
  {
    index: 372,
    name: 'Letmeape Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/372.png',
    uri: '/372',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.000309',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 69,
    },
  },
  {
    index: 373,
    name: 'Prime Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/373.png',
    uri: '/373',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.001637',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 250,
    },
  },
  {
    index: 374,
    name: 'Animated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/374.png',
    uri: '/374',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002670',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 449,
    },
  },
  {
    index: 375,
    name: 'Hollow Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/375.png',
    uri: '/375',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.009923',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 975,
    },
  },
  {
    index: 376,
    name: 'Long-Term Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/376.png',
    uri: '/376',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.000363',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 87,
    },
  },
  {
    index: 377,
    name: 'Raw Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/377.png',
    uri: '/377',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.000339',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 80,
    },
  },
  {
    index: 378,
    name: 'Junior Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/378.png',
    uri: '/378',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002271',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 318,
    },
  },
  {
    index: 379,
    name: 'Mark Cuban Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/379.png',
    uri: '/379',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.003829',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 807,
    },
  },
  {
    index: 380,
    name: 'Memorable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/380.png',
    uri: '/380',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.003796',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 801,
    },
  },
  {
    index: 381,
    name: 'Giant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/381.png',
    uri: '/381',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Gold Tooth',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.000019',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 3,
    },
  },
  {
    index: 382,
    name: 'Immense Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/382.png',
    uri: '/382',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.000175',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 56,
    },
  },
  {
    index: 383,
    name: 'Coordinated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/383.png',
    uri: '/383',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003792',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 798,
    },
  },
  {
    index: 384,
    name: 'Ian Balina Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/384.png',
    uri: '/384',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.003580',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 751,
    },
  },
  {
    index: 385,
    name: 'Fitting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/385.png',
    uri: '/385',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002561',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 404,
    },
  },
  {
    index: 386,
    name: 'Apprehensive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/386.png',
    uri: '/386',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.010534',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 980,
    },
  },
  {
    index: 387,
    name: 'Appropriate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/387.png',
    uri: '/387',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003102',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 635,
    },
  },
  {
    index: 388,
    name: 'Decimal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/388.png',
    uri: '/388',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002658',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 440,
    },
  },
  {
    index: 389,
    name: 'Belated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/389.png',
    uri: '/389',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Astronaut',
      rarityScore: '0.002601',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 419,
    },
  },
  {
    index: 390,
    name: 'Handy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/390.png',
    uri: '/390',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.002982',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 593,
    },
  },
  {
    index: 391,
    name: 'Artistic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/391.png',
    uri: '/391',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.007757',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 940,
    },
  },
  {
    index: 392,
    name: 'Great Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/392.png',
    uri: '/392',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.003054',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 619,
    },
  },
  {
    index: 393,
    name: 'Identical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/393.png',
    uri: '/393',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003594',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 753,
    },
  },
  {
    index: 394,
    name: 'Awesome Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/394.png',
    uri: '/394',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.003287',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 686,
    },
  },
  {
    index: 395,
    name: 'Unpleasant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/395.png',
    uri: '/395',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Sweat Band',
      rarityScore: '0.003464',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 721,
    },
  },
  {
    index: 396,
    name: 'Unfit Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/396.png',
    uri: '/396',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.000638',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 176,
    },
  },
  {
    index: 397,
    name: 'Leafy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/397.png',
    uri: '/397',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.004227',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 861,
    },
  },
  {
    index: 398,
    name: 'Colorless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/398.png',
    uri: '/398',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Avatar',
      rarityScore: '0.000893',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 215,
    },
  },
  {
    index: 399,
    name: 'Past Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/399.png',
    uri: '/399',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.002412',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 359,
    },
  },
  {
    index: 400,
    name: 'Bitlord Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/400.png',
    uri: '/400',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Astronaut',
      rarityScore: '0.007615',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 936,
    },
  },
  {
    index: 401,
    name: 'Likely Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/401.png',
    uri: '/401',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Brown Hair',
      rarityScore: '0.004914',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 899,
    },
  },
  {
    index: 402,
    name: 'Sizzling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/402.png',
    uri: '/402',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003146',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 646,
    },
  },
  {
    index: 403,
    name: 'Frizzy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/403.png',
    uri: '/403',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003601',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 755,
    },
  },
  {
    index: 404,
    name: 'Cluttered Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/404.png',
    uri: '/404',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.002420',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 361,
    },
  },
  {
    index: 405,
    name: 'Unfolded Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/405.png',
    uri: '/405',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Avatar',
      rarityScore: '0.000530',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 150,
    },
  },
  {
    index: 406,
    name: 'Beautiful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/406.png',
    uri: '/406',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.003371',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 706,
    },
  },
  {
    index: 407,
    name: 'Imaginary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/407.png',
    uri: '/407',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Pale Cowboy',
      rarityScore: '0.000031',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 7,
    },
  },
  {
    index: 408,
    name: 'Altcoinpsycho Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/408.png',
    uri: '/408',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.000391',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 99,
    },
  },
  {
    index: 409,
    name: 'Ambroid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/409.png',
    uri: '/409',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.000377',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 92,
    },
  },
  {
    index: 410,
    name: 'Harmonious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/410.png',
    uri: '/410',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.003062',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 625,
    },
  },
  {
    index: 411,
    name: 'Gray Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/411.png',
    uri: '/411',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Lazer',
      hats: 'Cop',
      rarityScore: '0.000896',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 217,
    },
  },
  {
    index: 412,
    name: 'Donalt Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/412.png',
    uri: '/412',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.003925',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 821,
    },
  },
  {
    index: 413,
    name: 'Low Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/413.png',
    uri: '/413',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.003912',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 820,
    },
  },
  {
    index: 414,
    name: 'Exemplary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/414.png',
    uri: '/414',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Lazer',
      hats: 'Visor',
      rarityScore: '0.000703',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 191,
    },
  },
  {
    index: 415,
    name: 'Repulsive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/415.png',
    uri: '/415',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans Red',
      hats: 'Brown Hair',
      rarityScore: '0.000059',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 11,
    },
  },
  {
    index: 416,
    name: 'Happy-Go-Lucky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/416.png',
    uri: '/416',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002505',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 386,
    },
  },
  {
    index: 417,
    name: 'Fumbling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/417.png',
    uri: '/417',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.002170',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 292,
    },
  },
  {
    index: 418,
    name: 'Gummy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/418.png',
    uri: '/418',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.000146',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 44,
    },
  },
  {
    index: 419,
    name: 'Bogus Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/419.png',
    uri: '/419',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003265',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 678,
    },
  },
  {
    index: 420,
    name: 'Selfish Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/420.png',
    uri: '/420',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003273',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 680,
    },
  },
  {
    index: 421,
    name: 'Perfumed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/421.png',
    uri: '/421',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Pale Cowboy',
      rarityScore: '0.000715',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 192,
    },
  },
  {
    index: 422,
    name: 'Punctual Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/422.png',
    uri: '/422',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002779',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 497,
    },
  },
  {
    index: 423,
    name: 'Jaded Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/423.png',
    uri: '/423',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.003284',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 685,
    },
  },
  {
    index: 424,
    name: 'Second Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/424.png',
    uri: '/424',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003036',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 608,
    },
  },
  {
    index: 425,
    name: 'Gifted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/425.png',
    uri: '/425',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.000425',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 116,
    },
  },
  {
    index: 426,
    name: 'Dazzling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/426.png',
    uri: '/426',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.002588',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 415,
    },
  },
  {
    index: 427,
    name: 'Immaculate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/427.png',
    uri: '/427',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003656',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 769,
    },
  },
  {
    index: 428,
    name: 'Deafening Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/428.png',
    uri: '/428',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002842',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 531,
    },
  },
  {
    index: 429,
    name: 'Peaceful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/429.png',
    uri: '/429',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003146',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 647,
    },
  },
  {
    index: 430,
    name: 'Clear-Cut Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/430.png',
    uri: '/430',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Astronaut',
      rarityScore: '0.012295',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 994,
    },
  },
  {
    index: 431,
    name: 'Assured Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/431.png',
    uri: '/431',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.002932',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 572,
    },
  },
  {
    index: 432,
    name: 'Glaring Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/432.png',
    uri: '/432',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.002460',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 373,
    },
  },
  {
    index: 433,
    name: 'Serene Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/433.png',
    uri: '/433',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.003042',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 612,
    },
  },
  {
    index: 434,
    name: 'Elderly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/434.png',
    uri: '/434',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.003768',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 790,
    },
  },
  {
    index: 435,
    name: 'Accomplished Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/435.png',
    uri: '/435',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003156',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 650,
    },
  },
  {
    index: 436,
    name: 'Kindly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/436.png',
    uri: '/436',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Sweat Band',
      rarityScore: '0.000753',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 199,
    },
  },
  {
    index: 437,
    name: 'Insidious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/437.png',
    uri: '/437',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.011205',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 988,
    },
  },
  {
    index: 438,
    name: 'Pertinent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/438.png',
    uri: '/438',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.002836',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 527,
    },
  },
  {
    index: 439,
    name: 'Bold Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/439.png',
    uri: '/439',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.003069',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 629,
    },
  },
  {
    index: 440,
    name: 'Far Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/440.png',
    uri: '/440',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.002107',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 285,
    },
  },
  {
    index: 441,
    name: 'Acidic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/441.png',
    uri: '/441',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.007789',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 942,
    },
  },
  {
    index: 442,
    name: 'Monthly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/442.png',
    uri: '/442',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.002463',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 374,
    },
  },
  {
    index: 443,
    name: 'Compassionate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/443.png',
    uri: '/443',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002931',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 571,
    },
  },
  {
    index: 444,
    name: 'Innocent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/444.png',
    uri: '/444',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.010385',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 978,
    },
  },
  {
    index: 445,
    name: 'Insubstantial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/445.png',
    uri: '/445',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.002927',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 566,
    },
  },
  {
    index: 446,
    name: 'Respectful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/446.png',
    uri: '/446',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002706',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 471,
    },
  },
  {
    index: 447,
    name: 'Arthur Hayes Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/447.png',
    uri: '/447',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003135',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 641,
    },
  },
  {
    index: 448,
    name: 'Grim Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/448.png',
    uri: '/448',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.003517',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 738,
    },
  },
  {
    index: 449,
    name: 'Andreas Antonopoulos Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/449.png',
    uri: '/449',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Dark Cowboy',
      rarityScore: '0.003736',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 785,
    },
  },
  {
    index: 450,
    name: 'Juicy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/450.png',
    uri: '/450',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Avatar',
      rarityScore: '0.000576',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 161,
    },
  },
  {
    index: 451,
    name: 'Academic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/451.png',
    uri: '/451',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.003059',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 620,
    },
  },
  {
    index: 452,
    name: 'Concerned Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/452.png',
    uri: '/452',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.002101',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 284,
    },
  },
  {
    index: 453,
    name: 'Acceptable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/453.png',
    uri: '/453',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.005789',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 917,
    },
  },
  {
    index: 454,
    name: 'Ill Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/454.png',
    uri: '/454',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Dark Cowboy',
      rarityScore: '0.003703',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 778,
    },
  },
  {
    index: 455,
    name: 'Muffled Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/455.png',
    uri: '/455',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002615',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 423,
    },
  },
  {
    index: 456,
    name: 'Eccentric Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/456.png',
    uri: '/456',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002386',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 351,
    },
  },
  {
    index: 457,
    name: 'Courteous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/457.png',
    uri: '/457',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Dark Cowboy',
      rarityScore: '0.001522',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 248,
    },
  },
  {
    index: 458,
    name: 'Green Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/458.png',
    uri: '/458',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.006853',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 926,
    },
  },
  {
    index: 459,
    name: 'Nocturnal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/459.png',
    uri: '/459',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.002479',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 379,
    },
  },
  {
    index: 460,
    name: 'Equal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/460.png',
    uri: '/460',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Banana Hair',
      rarityScore: '0.000334',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 77,
    },
  },
  {
    index: 461,
    name: 'Hungry Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/461.png',
    uri: '/461',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.004815',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 889,
    },
  },
  {
    index: 462,
    name: 'Illiterate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/462.png',
    uri: '/462',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.002609',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 420,
    },
  },
  {
    index: 463,
    name: 'Acrobatic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/463.png',
    uri: '/463',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.002714',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 474,
    },
  },
  {
    index: 464,
    name: 'Purple Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/464.png',
    uri: '/464',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003515',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 737,
    },
  },
  {
    index: 465,
    name: 'Messy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/465.png',
    uri: '/465',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.000541',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 154,
    },
  },
  {
    index: 466,
    name: 'Fluffy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/466.png',
    uri: '/466',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.003906',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 819,
    },
  },
  {
    index: 467,
    name: 'Puny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/467.png',
    uri: '/467',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.001032',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 232,
    },
  },
  {
    index: 468,
    name: 'Bad Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/468.png',
    uri: '/468',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002127',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 287,
    },
  },
  {
    index: 469,
    name: 'High-Level Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/469.png',
    uri: '/469',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.001952',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 265,
    },
  },
  {
    index: 470,
    name: 'Genuine Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/470.png',
    uri: '/470',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Visor',
      rarityScore: '0.002517',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 390,
    },
  },
  {
    index: 471,
    name: 'Grandiose Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/471.png',
    uri: '/471',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Visor',
      rarityScore: '0.000324',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 70,
    },
  },
  {
    index: 472,
    name: 'Constant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/472.png',
    uri: '/472',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Pale Cowboy',
      rarityScore: '0.000129',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 30,
    },
  },
  {
    index: 473,
    name: 'Immediate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/473.png',
    uri: '/473',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000563',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 159,
    },
  },
  {
    index: 474,
    name: 'Cultured Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/474.png',
    uri: '/474',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003597',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 754,
    },
  },
  {
    index: 475,
    name: 'Mortified Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/475.png',
    uri: '/475',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.002610',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 421,
    },
  },
  {
    index: 476,
    name: 'Flimsy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/476.png',
    uri: '/476',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.000343',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 81,
    },
  },
  {
    index: 477,
    name: 'Possible Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/477.png',
    uri: '/477',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Visor',
      rarityScore: '0.003655',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 768,
    },
  },
  {
    index: 478,
    name: 'Knotty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/478.png',
    uri: '/478',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.003793',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 800,
    },
  },
  {
    index: 479,
    name: 'Bountiful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/479.png',
    uri: '/479',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Avatar',
      rarityScore: '0.000132',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 33,
    },
  },
  {
    index: 480,
    name: 'Slim Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/480.png',
    uri: '/480',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.002673',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 450,
    },
  },
  {
    index: 481,
    name: 'Flamboyant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/481.png',
    uri: '/481',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.003316',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 695,
    },
  },
  {
    index: 482,
    name: 'Lumbering Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/482.png',
    uri: '/482',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.002773',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 495,
    },
  },
  {
    index: 483,
    name: 'Unfinished Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/483.png',
    uri: '/483',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.002383',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 350,
    },
  },
  {
    index: 484,
    name: 'Idiotic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/484.png',
    uri: '/484',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.000366',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 88,
    },
  },
  {
    index: 485,
    name: 'Juvenile Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/485.png',
    uri: '/485',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004776',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 887,
    },
  },
  {
    index: 486,
    name: 'Moist Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/486.png',
    uri: '/486',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans Red',
      hats: 'Cop',
      rarityScore: '0.000650',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 179,
    },
  },
  {
    index: 487,
    name: 'Complicated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/487.png',
    uri: '/487',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Pale Cowboy',
      rarityScore: '0.000799',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 206,
    },
  },
  {
    index: 488,
    name: 'French Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/488.png',
    uri: '/488',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.002877',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 544,
    },
  },
  {
    index: 489,
    name: 'Far-Out Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/489.png',
    uri: '/489',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.007348',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 932,
    },
  },
  {
    index: 490,
    name: 'Attached Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/490.png',
    uri: '/490',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003928',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 822,
    },
  },
  {
    index: 491,
    name: 'Educated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/491.png',
    uri: '/491',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Avatar',
      rarityScore: '0.000595',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 165,
    },
  },
  {
    index: 492,
    name: 'Glamorous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/492.png',
    uri: '/492',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002576',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 407,
    },
  },
  {
    index: 493,
    name: 'Majestic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/493.png',
    uri: '/493',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002918',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 561,
    },
  },
  {
    index: 494,
    name: 'Conscious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/494.png',
    uri: '/494',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.003028',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 603,
    },
  },
  {
    index: 495,
    name: 'Scratchy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/495.png',
    uri: '/495',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.002987',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 595,
    },
  },
  {
    index: 496,
    name: 'Different Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/496.png',
    uri: '/496',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.003485',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 729,
    },
  },
  {
    index: 497,
    name: 'Complex Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/497.png',
    uri: '/497',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002515',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 389,
    },
  },
  {
    index: 498,
    name: 'Jaunty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/498.png',
    uri: '/498',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Pearl',
      hats: 'Brown Hair',
      rarityScore: '0.000397',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 101,
    },
  },
  {
    index: 499,
    name: 'Roasted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/499.png',
    uri: '/499',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.005033',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 906,
    },
  },
  {
    index: 500,
    name: 'Doting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/500.png',
    uri: '/500',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Visor',
      rarityScore: '0.002013',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 273,
    },
  },
  {
    index: 501,
    name: 'Pale Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/501.png',
    uri: '/501',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.005000',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 903,
    },
  },
  {
    index: 502,
    name: 'Known Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/502.png',
    uri: '/502',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002386',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 352,
    },
  },
  {
    index: 503,
    name: 'Fresh Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/503.png',
    uri: '/503',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003804',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 803,
    },
  },
  {
    index: 504,
    name: 'Kosher Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/504.png',
    uri: '/504',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.004476',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 876,
    },
  },
  {
    index: 505,
    name: 'Extra-Large Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/505.png',
    uri: '/505',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.002586',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 414,
    },
  },
  {
    index: 506,
    name: 'Slight Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/506.png',
    uri: '/506',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Visor',
      rarityScore: '0.002273',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 320,
    },
  },
  {
    index: 507,
    name: 'Flawed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/507.png',
    uri: '/507',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Sweat Band',
      rarityScore: '0.003574',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 749,
    },
  },
  {
    index: 508,
    name: 'Miserly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/508.png',
    uri: '/508',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.002211',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 302,
    },
  },
  {
    index: 509,
    name: 'Adolescent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/509.png',
    uri: '/509',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.002664',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 444,
    },
  },
  {
    index: 510,
    name: 'Forsaken Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/510.png',
    uri: '/510',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.002532',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 394,
    },
  },
  {
    index: 511,
    name: 'Offensive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/511.png',
    uri: '/511',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.005113',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 910,
    },
  },
  {
    index: 512,
    name: 'Helpful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/512.png',
    uri: '/512',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002666',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 445,
    },
  },
  {
    index: 513,
    name: 'Filthy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/513.png',
    uri: '/513',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.005080',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 908,
    },
  },
  {
    index: 514,
    name: 'Unlucky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/514.png',
    uri: '/514',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.003039',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 609,
    },
  },
  {
    index: 515,
    name: 'Athletic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/515.png',
    uri: '/515',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.010962',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 986,
    },
  },
  {
    index: 516,
    name: 'Bouncy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/516.png',
    uri: '/516',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Red Tassle',
      rarityScore: '0.002912',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 555,
    },
  },
  {
    index: 517,
    name: 'Frilly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/517.png',
    uri: '/517',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Brown Hair',
      rarityScore: '0.001300',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 240,
    },
  },
  {
    index: 518,
    name: 'Scary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/518.png',
    uri: '/518',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.004943',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 901,
    },
  },
  {
    index: 519,
    name: 'Productive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/519.png',
    uri: '/519',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.002511',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 387,
    },
  },
  {
    index: 520,
    name: 'Novel Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/520.png',
    uri: '/520',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.005030',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 905,
    },
  },
  {
    index: 521,
    name: 'Knowledgeable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/521.png',
    uri: '/521',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.004206',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 857,
    },
  },
  {
    index: 522,
    name: 'Early Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/522.png',
    uri: '/522',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Red Tassle',
      rarityScore: '0.005014',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 904,
    },
  },
  {
    index: 523,
    name: 'Secret Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/523.png',
    uri: '/523',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Pale Cowboy',
      rarityScore: '0.000775',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 203,
    },
  },
  {
    index: 524,
    name: 'Faithful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/524.png',
    uri: '/524',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans Red',
      hats: 'Sweat Band',
      rarityScore: '0.000104',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 21,
    },
  },
  {
    index: 525,
    name: 'Minty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/525.png',
    uri: '/525',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002825',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 519,
    },
  },
  {
    index: 526,
    name: 'Deadly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/526.png',
    uri: '/526',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.003963',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 826,
    },
  },
  {
    index: 527,
    name: 'Flustered Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/527.png',
    uri: '/527',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.009546',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 967,
    },
  },
  {
    index: 528,
    name: 'Lucky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/528.png',
    uri: '/528',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.002570',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 405,
    },
  },
  {
    index: 529,
    name: 'Jam-Packed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/529.png',
    uri: '/529',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.000200',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 62,
    },
  },
  {
    index: 530,
    name: 'Uniform Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/530.png',
    uri: '/530',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.000095',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 20,
    },
  },
  {
    index: 531,
    name: 'Rare Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/531.png',
    uri: '/531',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Sweat Band',
      rarityScore: '0.003501',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 733,
    },
  },
  {
    index: 532,
    name: 'Optimal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/532.png',
    uri: '/532',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Pale Cowboy',
      rarityScore: '0.000088',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 18,
    },
  },
  {
    index: 533,
    name: 'Gaseous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/533.png',
    uri: '/533',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.005852',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 920,
    },
  },
  {
    index: 534,
    name: 'Crazy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/534.png',
    uri: '/534',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.002312',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 331,
    },
  },
  {
    index: 535,
    name: 'Sminem Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/535.png',
    uri: '/535',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003473',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 724,
    },
  },
  {
    index: 536,
    name: 'Famous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/536.png',
    uri: '/536',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.000533',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 151,
    },
  },
  {
    index: 537,
    name: 'Mysterious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/537.png',
    uri: '/537',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Visor',
      rarityScore: '0.001552',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 249,
    },
  },
  {
    index: 538,
    name: 'Gruesome Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/538.png',
    uri: '/538',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Astronaut',
      rarityScore: '0.000860',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 211,
    },
  },
  {
    index: 539,
    name: 'Neighboring Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/539.png',
    uri: '/539',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Dark Cowboy',
      rarityScore: '0.002585',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 411,
    },
  },
  {
    index: 540,
    name: 'Twin Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/540.png',
    uri: '/540',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.002667',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 446,
    },
  },
  {
    index: 541,
    name: 'Proper Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/541.png',
    uri: '/541',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.004152',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 850,
    },
  },
  {
    index: 542,
    name: 'Limp Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/542.png',
    uri: '/542',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.003498',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 732,
    },
  },
  {
    index: 543,
    name: 'Cumbersome Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/543.png',
    uri: '/543',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.000535',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 153,
    },
  },
  {
    index: 544,
    name: 'Aching Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/544.png',
    uri: '/544',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.003084',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 630,
    },
  },
  {
    index: 545,
    name: 'Homely Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/545.png',
    uri: '/545',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.002059',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 276,
    },
  },
  {
    index: 546,
    name: 'Curvy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/546.png',
    uri: '/546',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Astronaut',
      rarityScore: '0.001953',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 266,
    },
  },
  {
    index: 547,
    name: 'Excited Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/547.png',
    uri: '/547',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002874',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 543,
    },
  },
  {
    index: 548,
    name: 'Simple Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/548.png',
    uri: '/548',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.002280',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 323,
    },
  },
  {
    index: 549,
    name: 'Moral Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/549.png',
    uri: '/549',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.003887',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 817,
    },
  },
  {
    index: 550,
    name: 'Inversebrah Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/550.png',
    uri: '/550',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.000243',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 65,
    },
  },
  {
    index: 551,
    name: 'Ornate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/551.png',
    uri: '/551',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.002701',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 466,
    },
  },
  {
    index: 552,
    name: 'Kooky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/552.png',
    uri: '/552',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans Red',
      hats: 'Astronaut',
      rarityScore: '0.000526',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 148,
    },
  },
  {
    index: 553,
    name: 'Scientific Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/553.png',
    uri: '/553',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.004152',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 851,
    },
  },
  {
    index: 554,
    name: 'Unlawful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/554.png',
    uri: '/554',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003756',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 788,
    },
  },
  {
    index: 555,
    name: 'Infantile Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/555.png',
    uri: '/555',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.000724',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 193,
    },
  },
  {
    index: 556,
    name: 'Evil Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/556.png',
    uri: '/556',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002838',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 529,
    },
  },
  {
    index: 557,
    name: 'Damp Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/557.png',
    uri: '/557',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002518',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 391,
    },
  },
  {
    index: 558,
    name: 'Partial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/558.png',
    uri: '/558',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.010847',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 985,
    },
  },
  {
    index: 559,
    name: 'Conventional Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/559.png',
    uri: '/559',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.004208',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 858,
    },
  },
  {
    index: 560,
    name: 'Supportive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/560.png',
    uri: '/560',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003842',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 810,
    },
  },
  {
    index: 561,
    name: 'Active Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/561.png',
    uri: '/561',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003271',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 679,
    },
  },
  {
    index: 562,
    name: 'Ragged Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/562.png',
    uri: '/562',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.002942',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 574,
    },
  },
  {
    index: 563,
    name: 'Difficult Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/563.png',
    uri: '/563',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.003409',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 714,
    },
  },
  {
    index: 564,
    name: 'Minor Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/564.png',
    uri: '/564',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Visor',
      rarityScore: '0.003050',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 617,
    },
  },
  {
    index: 565,
    name: 'Nutritious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/565.png',
    uri: '/565',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Sweat Band',
      rarityScore: '0.005748',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 916,
    },
  },
  {
    index: 566,
    name: 'Ethical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/566.png',
    uri: '/566',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Visor',
      rarityScore: '0.002759',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 491,
    },
  },
  {
    index: 567,
    name: 'Oddball Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/567.png',
    uri: '/567',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Lazer',
      hats: 'Banana Hair',
      rarityScore: '0.000060',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 12,
    },
  },
  {
    index: 568,
    name: 'Noteworthy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/568.png',
    uri: '/568',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'Sans Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.000045',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 8,
    },
  },
  {
    index: 569,
    name: 'Horrible Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/569.png',
    uri: '/569',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Sans',
      hats: 'Visor',
      rarityScore: '0.000337',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 79,
    },
  },
  {
    index: 570,
    name: 'Intrepid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/570.png',
    uri: '/570',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.004022',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 836,
    },
  },
  {
    index: 571,
    name: 'Multicolored Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/571.png',
    uri: '/571',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.003261',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 677,
    },
  },
  {
    index: 572,
    name: 'Willy Woo Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/572.png',
    uri: '/572',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.003000',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 598,
    },
  },
  {
    index: 573,
    name: 'Grimy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/573.png',
    uri: '/573',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.000646',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 178,
    },
  },
  {
    index: 574,
    name: 'Colorful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/574.png',
    uri: '/574',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002859',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 538,
    },
  },
  {
    index: 575,
    name: 'Easy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/575.png',
    uri: '/575',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.009844',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 974,
    },
  },
  {
    index: 576,
    name: 'Lovely Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/576.png',
    uri: '/576',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002081',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 282,
    },
  },
  {
    index: 577,
    name: 'Natural Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/577.png',
    uri: '/577',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.003535',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 742,
    },
  },
  {
    index: 578,
    name: 'Gripping Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/578.png',
    uri: '/578',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Sweat Band',
      rarityScore: '0.002261',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 316,
    },
  },
  {
    index: 579,
    name: 'Frayed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/579.png',
    uri: '/579',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.009225',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 963,
    },
  },
  {
    index: 580,
    name: 'Scholarly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/580.png',
    uri: '/580',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.007793',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 943,
    },
  },
  {
    index: 581,
    name: 'Defenseless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/581.png',
    uri: '/581',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Astronaut',
      rarityScore: '0.002318',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 334,
    },
  },
  {
    index: 582,
    name: 'Beloved Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/582.png',
    uri: '/582',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Avatar',
      rarityScore: '0.000411',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 108,
    },
  },
  {
    index: 583,
    name: 'Fragrant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/583.png',
    uri: '/583',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.002299',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 326,
    },
  },
  {
    index: 584,
    name: 'Vitalik Buterin Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/584.png',
    uri: '/584',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003792',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 799,
    },
  },
  {
    index: 585,
    name: 'Ultimate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/585.png',
    uri: '/585',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.004354',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 868,
    },
  },
  {
    index: 586,
    name: 'Vidar Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/586.png',
    uri: '/586',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.002271',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 319,
    },
  },
  {
    index: 587,
    name: 'Big Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/587.png',
    uri: '/587',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Pale Cowboy',
      rarityScore: '0.000858',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 210,
    },
  },
  {
    index: 588,
    name: 'Bsmartinvest Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/588.png',
    uri: '/588',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.002329',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 338,
    },
  },
  {
    index: 589,
    name: 'Handmade Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/589.png',
    uri: '/589',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.002735',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 480,
    },
  },
  {
    index: 590,
    name: 'Prestigious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/590.png',
    uri: '/590',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.002696',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 461,
    },
  },
  {
    index: 591,
    name: 'Pretty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/591.png',
    uri: '/591',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Visor',
      rarityScore: '0.002303',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 329,
    },
  },
  {
    index: 592,
    name: 'Female Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/592.png',
    uri: '/592',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.002299',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 327,
    },
  },
  {
    index: 593,
    name: 'Unhappy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/593.png',
    uri: '/593',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.003769',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 791,
    },
  },
  {
    index: 594,
    name: 'Meager Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/594.png',
    uri: '/594',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.001928',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 262,
    },
  },
  {
    index: 595,
    name: 'Venturecoinist Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/595.png',
    uri: '/595',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.000459',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 130,
    },
  },
  {
    index: 596,
    name: 'Scrawny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/596.png',
    uri: '/596',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.004538',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 880,
    },
  },
  {
    index: 597,
    name: 'Nasty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/597.png',
    uri: '/597',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.000468',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 131,
    },
  },
  {
    index: 598,
    name: 'Useless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/598.png',
    uri: '/598',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.003042',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 613,
    },
  },
  {
    index: 599,
    name: 'Ringed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/599.png',
    uri: '/599',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.007520',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 935,
    },
  },
  {
    index: 600,
    name: 'Slimy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/600.png',
    uri: '/600',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Brown Hair',
      rarityScore: '0.003161',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 653,
    },
  },
  {
    index: 601,
    name: 'Delightful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/601.png',
    uri: '/601',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans Red',
      hats: 'Third Eye',
      rarityScore: '0.000672',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 184,
    },
  },
  {
    index: 602,
    name: 'Gloomy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/602.png',
    uri: '/602',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.000416',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 110,
    },
  },
  {
    index: 603,
    name: 'Elliotrades Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/603.png',
    uri: '/603',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.000146',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 45,
    },
  },
  {
    index: 604,
    name: 'Droopy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/604.png',
    uri: '/604',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.008953',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 962,
    },
  },
  {
    index: 605,
    name: 'Exotic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/605.png',
    uri: '/605',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.003238',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 673,
    },
  },
  {
    index: 606,
    name: 'Frozen Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/606.png',
    uri: '/606',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.003929',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 823,
    },
  },
  {
    index: 607,
    name: 'Anguished Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/607.png',
    uri: '/607',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.000404',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 105,
    },
  },
  {
    index: 608,
    name: 'Corny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/608.png',
    uri: '/608',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Avatar',
      rarityScore: '0.000559',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 158,
    },
  },
  {
    index: 609,
    name: 'Little Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/609.png',
    uri: '/609',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.002705',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 470,
    },
  },
  {
    index: 610,
    name: 'Dull Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/610.png',
    uri: '/610',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.004665',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 883,
    },
  },
  {
    index: 611,
    name: 'Daring Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/611.png',
    uri: '/611',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.000423',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 114,
    },
  },
  {
    index: 612,
    name: 'Ideal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/612.png',
    uri: '/612',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003489',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 730,
    },
  },
  {
    index: 613,
    name: 'Instructive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/613.png',
    uri: '/613',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.011450',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 991,
    },
  },
  {
    index: 614,
    name: 'Left Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/614.png',
    uri: '/614',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.007204',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 928,
    },
  },
  {
    index: 615,
    name: 'Thinkingusd Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/615.png',
    uri: '/615',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.007992',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 949,
    },
  },
  {
    index: 616,
    name: 'Curly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/616.png',
    uri: '/616',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003365',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 704,
    },
  },
  {
    index: 617,
    name: 'Authentic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/617.png',
    uri: '/617',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003064',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 627,
    },
  },
  {
    index: 618,
    name: 'Fluid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/618.png',
    uri: '/618',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002542',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 397,
    },
  },
  {
    index: 619,
    name: 'Back Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/619.png',
    uri: '/619',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.003277',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 681,
    },
  },
  {
    index: 620,
    name: 'Justin Sun Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/620.png',
    uri: '/620',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002923',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 564,
    },
  },
  {
    index: 621,
    name: 'Extroverted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/621.png',
    uri: '/621',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Lazer',
      hats: 'Third Eye',
      rarityScore: '0.001301',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 241,
    },
  },
  {
    index: 622,
    name: 'Opulent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/622.png',
    uri: '/622',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Cop',
      rarityScore: '0.002822',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 515,
    },
  },
  {
    index: 623,
    name: 'Adorable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/623.png',
    uri: '/623',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.000104',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 22,
    },
  },
  {
    index: 624,
    name: 'Circular Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/624.png',
    uri: '/624',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.000154',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 49,
    },
  },
  {
    index: 625,
    name: 'Perky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/625.png',
    uri: '/625',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.002921',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 563,
    },
  },
  {
    index: 626,
    name: 'Feminine Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/626.png',
    uri: '/626',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Visor',
      rarityScore: '0.001654',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 253,
    },
  },
  {
    index: 627,
    name: 'Imperfect Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/627.png',
    uri: '/627',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.002182',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 295,
    },
  },
  {
    index: 628,
    name: 'Damaged Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/628.png',
    uri: '/628',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000168',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 53,
    },
  },
  {
    index: 629,
    name: 'Cylindrical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/629.png',
    uri: '/629',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002946',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 577,
    },
  },
  {
    index: 630,
    name: 'Defi Khaled Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/630.png',
    uri: '/630',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.003194',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 662,
    },
  },
  {
    index: 631,
    name: 'Able Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/631.png',
    uri: '/631',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.002826',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 520,
    },
  },
  {
    index: 632,
    name: 'Coarse Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/632.png',
    uri: '/632',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.003878',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 816,
    },
  },
  {
    index: 633,
    name: 'Elliptical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/633.png',
    uri: '/633',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003788',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 797,
    },
  },
  {
    index: 634,
    name: 'Closed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/634.png',
    uri: '/634',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.004231',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 862,
    },
  },
  {
    index: 635,
    name: 'Menacing Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/635.png',
    uri: '/635',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002864',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 540,
    },
  },
  {
    index: 636,
    name: 'Tidy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/636.png',
    uri: '/636',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Banana Hair',
      rarityScore: '0.000153',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 48,
    },
  },
  {
    index: 637,
    name: 'Mindless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/637.png',
    uri: '/637',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Astronaut',
      rarityScore: '0.008466',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 957,
    },
  },
  {
    index: 638,
    name: 'Considerate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/638.png',
    uri: '/638',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.004025',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 838,
    },
  },
  {
    index: 639,
    name: 'Adept Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/639.png',
    uri: '/639',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.003860',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 813,
    },
  },
  {
    index: 640,
    name: 'Black Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/640.png',
    uri: '/640',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.000207',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 63,
    },
  },
  {
    index: 641,
    name: 'Thunderous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/641.png',
    uri: '/641',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002246',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 307,
    },
  },
  {
    index: 642,
    name: 'Uneven Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/642.png',
    uri: '/642',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.002663',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 443,
    },
  },
  {
    index: 643,
    name: 'Boring Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/643.png',
    uri: '/643',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002393',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 354,
    },
  },
  {
    index: 644,
    name: 'Jealous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/644.png',
    uri: '/644',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.003466',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 723,
    },
  },
  {
    index: 645,
    name: 'Cranky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/645.png',
    uri: '/645',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.008871',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 961,
    },
  },
  {
    index: 646,
    name: 'Corrupt Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/646.png',
    uri: '/646',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.012959',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 998,
    },
  },
  {
    index: 647,
    name: 'Nice Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/647.png',
    uri: '/647',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.000406',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 107,
    },
  },
  {
    index: 648,
    name: 'Turbulent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/648.png',
    uri: '/648',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002247',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 309,
    },
  },
  {
    index: 649,
    name: 'Neglected Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/649.png',
    uri: '/649',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.007661',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 939,
    },
  },
  {
    index: 650,
    name: 'Itchy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/650.png',
    uri: '/650',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002783',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 499,
    },
  },
  {
    index: 651,
    name: 'Drab Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/651.png',
    uri: '/651',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.000521',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 147,
    },
  },
  {
    index: 652,
    name: 'Satoshi Nakamoto Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/652.png',
    uri: '/652',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.000130',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 31,
    },
  },
  {
    index: 653,
    name: 'Glorious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/653.png',
    uri: '/653',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.002916',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 556,
    },
  },
  {
    index: 654,
    name: 'Growing Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/654.png',
    uri: '/654',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003402',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 712,
    },
  },
  {
    index: 655,
    name: 'Meaty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/655.png',
    uri: '/655',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.002669',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 448,
    },
  },
  {
    index: 656,
    name: 'Quaint Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/656.png',
    uri: '/656',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Sweat Band',
      rarityScore: '0.002328',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 336,
    },
  },
  {
    index: 657,
    name: 'Luminous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/657.png',
    uri: '/657',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003313',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 694,
    },
  },
  {
    index: 658,
    name: 'Pure Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/658.png',
    uri: '/658',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.003693',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 775,
    },
  },
  {
    index: 659,
    name: 'Extraneous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/659.png',
    uri: '/659',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.007863',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 946,
    },
  },
  {
    index: 660,
    name: 'Glittering Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/660.png',
    uri: '/660',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.003963',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 827,
    },
  },
  {
    index: 661,
    name: 'Triangular Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/661.png',
    uri: '/661',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.008572',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 958,
    },
  },
  {
    index: 662,
    name: 'Dramatic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/662.png',
    uri: '/662',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.002339',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 339,
    },
  },
  {
    index: 663,
    name: 'Crushing Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/663.png',
    uri: '/663',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.000481',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 136,
    },
  },
  {
    index: 664,
    name: 'Knowing Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/664.png',
    uri: '/664',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.000692',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 190,
    },
  },
  {
    index: 665,
    name: 'Gregarious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/665.png',
    uri: '/665',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Pale Cowboy',
      rarityScore: '0.002260',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 315,
    },
  },
  {
    index: 666,
    name: 'Papawheelie Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/666.png',
    uri: '/666',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Lazer',
      hats: 'Red Tassle',
      rarityScore: '0.000157',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 50,
    },
  },
  {
    index: 667,
    name: 'Tired Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/667.png',
    uri: '/667',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003159',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 652,
    },
  },
  {
    index: 668,
    name: 'Foolhardy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/668.png',
    uri: '/668',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.002581',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 409,
    },
  },
  {
    index: 669,
    name: 'Cool Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/669.png',
    uri: '/669',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.003212',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 664,
    },
  },
  {
    index: 670,
    name: 'False Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/670.png',
    uri: '/670',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.004154',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 852,
    },
  },
  {
    index: 671,
    name: 'Classic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/671.png',
    uri: '/671',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002297',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 325,
    },
  },
  {
    index: 672,
    name: 'Motionless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/672.png',
    uri: '/672',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.003729',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 784,
    },
  },
  {
    index: 673,
    name: 'Required Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/673.png',
    uri: '/673',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.002370',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 346,
    },
  },
  {
    index: 674,
    name: 'Boiling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/674.png',
    uri: '/674',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.004385',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 869,
    },
  },
  {
    index: 675,
    name: 'Bullyesq Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/675.png',
    uri: '/675',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.000402',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 102,
    },
  },
  {
    index: 676,
    name: 'Trained Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/676.png',
    uri: '/676',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.001463',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 245,
    },
  },
  {
    index: 677,
    name: 'Only Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/677.png',
    uri: '/677',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.005389',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 915,
    },
  },
  {
    index: 678,
    name: 'Querulous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/678.png',
    uri: '/678',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Dark Cowboy',
      rarityScore: '0.002585',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 412,
    },
  },
  {
    index: 679,
    name: 'Large Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/679.png',
    uri: '/679',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.002961',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 583,
    },
  },
  {
    index: 680,
    name: 'Breakable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/680.png',
    uri: '/680',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Cop',
      rarityScore: '0.002353',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 341,
    },
  },
  {
    index: 681,
    name: 'Glistening Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/681.png',
    uri: '/681',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.010736',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 982,
    },
  },
  {
    index: 682,
    name: 'Torn Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/682.png',
    uri: '/682',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002726',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 478,
    },
  },
  {
    index: 683,
    name: 'Oily Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/683.png',
    uri: '/683',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.002625',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 425,
    },
  },
  {
    index: 684,
    name: 'Rash Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/684.png',
    uri: '/684',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.000790',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 205,
    },
  },
  {
    index: 685,
    name: 'Harmless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/685.png',
    uri: '/685',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.009339',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 964,
    },
  },
  {
    index: 686,
    name: 'Crypto Cousin Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/686.png',
    uri: '/686',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Brown Hair',
      rarityScore: '0.000495',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 140,
    },
  },
  {
    index: 687,
    name: 'Frigid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/687.png',
    uri: '/687',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003059',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 621,
    },
  },
  {
    index: 688,
    name: 'Thecryptodog Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/688.png',
    uri: '/688',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.003149',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 649,
    },
  },
  {
    index: 689,
    name: 'Normal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/689.png',
    uri: '/689',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.002643',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 432,
    },
  },
  {
    index: 690,
    name: 'Fomosaurus Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/690.png',
    uri: '/690',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Dark Cowboy',
      rarityScore: '0.002918',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 562,
    },
  },
  {
    index: 691,
    name: 'Cryptomessiah Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/691.png',
    uri: '/691',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Red Tassle',
      rarityScore: '0.002313',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 332,
    },
  },
  {
    index: 692,
    name: 'Content Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/692.png',
    uri: '/692',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.000196',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 60,
    },
  },
  {
    index: 693,
    name: 'Quintessential Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/693.png',
    uri: '/693',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Banana Hair',
      rarityScore: '0.000955',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 227,
    },
  },
  {
    index: 694,
    name: 'Any Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/694.png',
    uri: '/694',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Sweat Band',
      rarityScore: '0.004031',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 839,
    },
  },
  {
    index: 695,
    name: 'Frightened Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/695.png',
    uri: '/695',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.001806',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 255,
    },
  },
  {
    index: 696,
    name: 'Adored Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/696.png',
    uri: '/696',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.001650',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 251,
    },
  },
  {
    index: 697,
    name: 'Sinful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/697.png',
    uri: '/697',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002890',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 548,
    },
  },
  {
    index: 698,
    name: 'Cute Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/698.png',
    uri: '/698',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.003491',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 731,
    },
  },
  {
    index: 699,
    name: 'Heartfelt Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/699.png',
    uri: '/699',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.000441',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 122,
    },
  },
  {
    index: 700,
    name: 'Medical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/700.png',
    uri: '/700',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.009583',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 968,
    },
  },
  {
    index: 701,
    name: 'Fuzzy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/701.png',
    uri: '/701',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Pale Cowboy',
      rarityScore: '0.000874',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 214,
    },
  },
  {
    index: 702,
    name: 'Harmful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/702.png',
    uri: '/702',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.000421',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 112,
    },
  },
  {
    index: 703,
    name: 'Annual Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/703.png',
    uri: '/703',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002632',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 430,
    },
  },
  {
    index: 704,
    name: 'Light Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/704.png',
    uri: '/704',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.000196',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 61,
    },
  },
  {
    index: 705,
    name: 'Hairy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/705.png',
    uri: '/705',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Lazer',
      hats: 'Dark Cowboy',
      rarityScore: '0.000898',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 218,
    },
  },
  {
    index: 706,
    name: 'Flashy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/706.png',
    uri: '/706',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Grape Purple',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Red Tassle',
      rarityScore: '0.000017',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 2,
    },
  },
  {
    index: 707,
    name: 'Envious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/707.png',
    uri: '/707',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Sweat Band',
      rarityScore: '0.002232',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 305,
    },
  },
  {
    index: 708,
    name: 'Major Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/708.png',
    uri: '/708',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.002759',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 492,
    },
  },
  {
    index: 709,
    name: 'Immaterial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/709.png',
    uri: '/709',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Red Tassle',
      rarityScore: '0.003413',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 716,
    },
  },
  {
    index: 710,
    name: 'Dark Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/710.png',
    uri: '/710',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.012782',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 997,
    },
  },
  {
    index: 711,
    name: 'Meek Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/711.png',
    uri: '/711',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000452',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 125,
    },
  },
  {
    index: 712,
    name: 'Misguided Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/712.png',
    uri: '/712',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000453',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 127,
    },
  },
  {
    index: 713,
    name: 'Barren Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/713.png',
    uri: '/713',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Cop',
      rarityScore: '0.002302',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 328,
    },
  },
  {
    index: 714,
    name: 'Bog Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/714.png',
    uri: '/714',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.002679',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 455,
    },
  },
  {
    index: 715,
    name: 'Big-Hearted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/715.png',
    uri: '/715',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.000412',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 109,
    },
  },
  {
    index: 716,
    name: 'Single Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/716.png',
    uri: '/716',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.003367',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 705,
    },
  },
  {
    index: 717,
    name: 'Fair Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/717.png',
    uri: '/717',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.000590',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 164,
    },
  },
  {
    index: 718,
    name: 'Nutty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/718.png',
    uri: '/718',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000493',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 139,
    },
  },
  {
    index: 719,
    name: 'Tragic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/719.png',
    uri: '/719',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.003249',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 674,
    },
  },
  {
    index: 720,
    name: 'Late Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/720.png',
    uri: '/720',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.003683',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 773,
    },
  },
  {
    index: 721,
    name: 'Passionate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/721.png',
    uri: '/721',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.010186',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 976,
    },
  },
  {
    index: 722,
    name: 'Happy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/722.png',
    uri: '/722',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.001148',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 236,
    },
  },
  {
    index: 723,
    name: 'Bigcheds Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/723.png',
    uri: '/723',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.000474',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 134,
    },
  },
  {
    index: 724,
    name: 'Faint Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/724.png',
    uri: '/724',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.000138',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 36,
    },
  },
  {
    index: 725,
    name: 'Bossy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/725.png',
    uri: '/725',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.002699',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 463,
    },
  },
  {
    index: 726,
    name: 'Dead Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/726.png',
    uri: '/726',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Astronaut',
      rarityScore: '0.002964',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 585,
    },
  },
  {
    index: 727,
    name: 'Pushy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/727.png',
    uri: '/727',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Pale Cowboy',
      rarityScore: '0.000746',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 196,
    },
  },
  {
    index: 728,
    name: 'Clever Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/728.png',
    uri: '/728',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Brown Hair',
      rarityScore: '0.002858',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 537,
    },
  },
  {
    index: 729,
    name: 'Focused Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/729.png',
    uri: '/729',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Lazer',
      hats: 'Dark Cowboy',
      rarityScore: '0.000953',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 226,
    },
  },
  {
    index: 730,
    name: 'Simplistic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/730.png',
    uri: '/730',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Avatar',
      rarityScore: '0.000607',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 167,
    },
  },
  {
    index: 731,
    name: 'Baggy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/731.png',
    uri: '/731',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.003302',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 690,
    },
  },
  {
    index: 732,
    name: 'Live Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/732.png',
    uri: '/732',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.000403',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 103,
    },
  },
  {
    index: 733,
    name: 'Hardfork Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/733.png',
    uri: '/733',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.000332',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 74,
    },
  },
  {
    index: 734,
    name: 'Hospitable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/734.png',
    uri: '/734',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.004269',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 865,
    },
  },
  {
    index: 735,
    name: 'Greedy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/735.png',
    uri: '/735',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.007847',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 944,
    },
  },
  {
    index: 736,
    name: 'Private Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/736.png',
    uri: '/736',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Pale Cowboy',
      rarityScore: '0.000115',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 26,
    },
  },
  {
    index: 737,
    name: 'Dry Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/737.png',
    uri: '/737',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.002700',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 465,
    },
  },
  {
    index: 738,
    name: 'Miniature Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/738.png',
    uri: '/738',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.002943',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 575,
    },
  },
  {
    index: 739,
    name: 'Marvelous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/739.png',
    uri: '/739',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.002662',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 442,
    },
  },
  {
    index: 740,
    name: 'Secondary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/740.png',
    uri: '/740',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Cop',
      rarityScore: '0.001653',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 252,
    },
  },
  {
    index: 741,
    name: 'Common Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/741.png',
    uri: '/741',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Avatar',
      rarityScore: '0.000904',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 220,
    },
  },
  {
    index: 742,
    name: 'Hot Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/742.png',
    uri: '/742',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.003669',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 770,
    },
  },
  {
    index: 743,
    name: 'Motherly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/743.png',
    uri: '/743',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.002464',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 375,
    },
  },
  {
    index: 744,
    name: 'Tinted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/744.png',
    uri: '/744',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Pale Cowboy',
      rarityScore: '0.000670',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 183,
    },
  },
  {
    index: 745,
    name: 'Comfortable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/745.png',
    uri: '/745',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.003214',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 665,
    },
  },
  {
    index: 746,
    name: 'Jumbo Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/746.png',
    uri: '/746',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002457',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 372,
    },
  },
  {
    index: 747,
    name: 'Nippy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/747.png',
    uri: '/747',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000731',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 194,
    },
  },
  {
    index: 748,
    name: 'Limited Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/748.png',
    uri: '/748',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.002142',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 290,
    },
  },
  {
    index: 749,
    name: 'Self-Reliant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/749.png',
    uri: '/749',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002772',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 494,
    },
  },
  {
    index: 750,
    name: 'Nautical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/750.png',
    uri: '/750',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002927',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 567,
    },
  },
  {
    index: 751,
    name: 'Experienced Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/751.png',
    uri: '/751',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Avatar',
      rarityScore: '0.000619',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 170,
    },
  },
  {
    index: 752,
    name: 'Front Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/752.png',
    uri: '/752',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Visor',
      rarityScore: '0.003990',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 832,
    },
  },
  {
    index: 753,
    name: 'Hoarse Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/753.png',
    uri: '/753',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.002279',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 322,
    },
  },
  {
    index: 754,
    name: 'Jittery Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/754.png',
    uri: '/754',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.002735',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 481,
    },
  },
  {
    index: 755,
    name: 'Infinite Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/755.png',
    uri: '/755',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.002755',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 490,
    },
  },
  {
    index: 756,
    name: 'Flippant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/756.png',
    uri: '/756',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Shades',
      hats: 'Avatar',
      rarityScore: '0.000085',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 16,
    },
  },
  {
    index: 757,
    name: 'Jolly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/757.png',
    uri: '/757',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.004773',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 886,
    },
  },
  {
    index: 758,
    name: 'Gleeful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/758.png',
    uri: '/758',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Sans Red',
      hats: 'Astronaut',
      rarityScore: '0.000738',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 195,
    },
  },
  {
    index: 759,
    name: 'Grouchy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/759.png',
    uri: '/759',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003551',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 746,
    },
  },
  {
    index: 760,
    name: 'Haunting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/760.png',
    uri: '/760',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.007945',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 947,
    },
  },
  {
    index: 761,
    name: 'Fantastic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/761.png',
    uri: '/761',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.009741',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 972,
    },
  },
  {
    index: 762,
    name: 'Cruel Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/762.png',
    uri: '/762',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003005',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 601,
    },
  },
  {
    index: 763,
    name: 'Utter Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/763.png',
    uri: '/763',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Sweat Band',
      rarityScore: '0.000281',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 67,
    },
  },
  {
    index: 764,
    name: 'Admired Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/764.png',
    uri: '/764',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002551',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 400,
    },
  },
  {
    index: 765,
    name: 'Favorite Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/765.png',
    uri: '/765',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.002802',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 506,
    },
  },
  {
    index: 766,
    name: 'Humongous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/766.png',
    uri: '/766',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000377',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 93,
    },
  },
  {
    index: 767,
    name: 'Quick Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/767.png',
    uri: '/767',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Sweat Band',
      rarityScore: '0.001958',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 267,
    },
  },
  {
    index: 768,
    name: 'Tricky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/768.png',
    uri: '/768',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003535',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 743,
    },
  },
  {
    index: 769,
    name: 'Babyish Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/769.png',
    uri: '/769',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.003399',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 710,
    },
  },
  {
    index: 770,
    name: 'Mixed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/770.png',
    uri: '/770',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Visor',
      rarityScore: '0.001996',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 271,
    },
  },
  {
    index: 771,
    name: 'Severe Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/771.png',
    uri: '/771',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.006570',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 924,
    },
  },
  {
    index: 772,
    name: 'Open Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/772.png',
    uri: '/772',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.002651',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 437,
    },
  },
  {
    index: 773,
    name: 'First Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/773.png',
    uri: '/773',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.003608',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 758,
    },
  },
  {
    index: 774,
    name: 'Functional Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/774.png',
    uri: '/774',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.002232',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 306,
    },
  },
  {
    index: 775,
    name: 'Musty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/775.png',
    uri: '/775',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.002392',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 353,
    },
  },
  {
    index: 776,
    name: 'Last Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/776.png',
    uri: '/776',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.000108',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 23,
    },
  },
  {
    index: 777,
    name: 'Long Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/777.png',
    uri: '/777',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.000327',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 71,
    },
  },
  {
    index: 778,
    name: 'Slippery Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/778.png',
    uri: '/778',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.002249',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 311,
    },
  },
  {
    index: 779,
    name: 'Jovial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/779.png',
    uri: '/779',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.005133',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 911,
    },
  },
  {
    index: 780,
    name: 'John Mcafee Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/780.png',
    uri: '/780',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003903',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 818,
    },
  },
  {
    index: 781,
    name: 'Lost Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/781.png',
    uri: '/781',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Lazer',
      hats: 'Astronaut',
      rarityScore: '0.001234',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 239,
    },
  },
  {
    index: 782,
    name: 'Key Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/782.png',
    uri: '/782',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Shades',
      hats: 'Red Tassle',
      rarityScore: '0.000491',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 138,
    },
  },
  {
    index: 783,
    name: 'Cloudy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/783.png',
    uri: '/783',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002974',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 591,
    },
  },
  {
    index: 784,
    name: 'Fast Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/784.png',
    uri: '/784',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.003346',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 701,
    },
  },
  {
    index: 785,
    name: 'Rigid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/785.png',
    uri: '/785',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.002379',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 348,
    },
  },
  {
    index: 786,
    name: 'Ill-Informed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/786.png',
    uri: '/786',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.002269',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 317,
    },
  },
  {
    index: 787,
    name: 'Favorable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/787.png',
    uri: '/787',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.003742',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 786,
    },
  },
  {
    index: 788,
    name: 'Healthy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/788.png',
    uri: '/788',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.003372',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 707,
    },
  },
  {
    index: 789,
    name: 'Galaxybtc Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/789.png',
    uri: '/789',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.002916',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 557,
    },
  },
  {
    index: 790,
    name: 'Glossy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/790.png',
    uri: '/790',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Avatar',
      rarityScore: '0.000680',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 186,
    },
  },
  {
    index: 791,
    name: 'Naval Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/791.png',
    uri: '/791',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.002916',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 558,
    },
  },
  {
    index: 792,
    name: 'Blissful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/792.png',
    uri: '/792',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003552',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 747,
    },
  },
  {
    index: 793,
    name: 'Offbeat Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/793.png',
    uri: '/793',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.003002',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 599,
    },
  },
  {
    index: 794,
    name: 'Masculine Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/794.png',
    uri: '/794',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002327',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 335,
    },
  },
  {
    index: 795,
    name: 'Blaring Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/795.png',
    uri: '/795',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.008431',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 956,
    },
  },
  {
    index: 796,
    name: 'Medium Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/796.png',
    uri: '/796',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Astronaut',
      rarityScore: '0.003727',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 783,
    },
  },
  {
    index: 797,
    name: 'Occasional Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/797.png',
    uri: '/797',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.004945',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 902,
    },
  },
  {
    index: 798,
    name: 'Cooperative Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/798.png',
    uri: '/798',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.002616',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 424,
    },
  },
  {
    index: 799,
    name: 'Max Keiser Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/799.png',
    uri: '/799',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002074',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 280,
    },
  },
  {
    index: 800,
    name: 'Fat Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/800.png',
    uri: '/800',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Pale Cowboy',
      rarityScore: '0.000753',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 200,
    },
  },
  {
    index: 801,
    name: 'Notable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/801.png',
    uri: '/801',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.003451',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 719,
    },
  },
  {
    index: 802,
    name: 'Superficial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/802.png',
    uri: '/802',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Dark Cowboy',
      rarityScore: '0.003033',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 605,
    },
  },
  {
    index: 803,
    name: 'Excellent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/803.png',
    uri: '/803',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.007365',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 933,
    },
  },
  {
    index: 804,
    name: 'Friendly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/804.png',
    uri: '/804',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.000087',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 17,
    },
  },
  {
    index: 805,
    name: 'Poor Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/805.png',
    uri: '/805',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003138',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 643,
    },
  },
  {
    index: 806,
    name: 'Feisty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/806.png',
    uri: '/806',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Visor',
      rarityScore: '0.002246',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 308,
    },
  },
  {
    index: 807,
    name: 'Pastel Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/807.png',
    uri: '/807',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Red Tassle',
      rarityScore: '0.003189',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 661,
    },
  },
  {
    index: 808,
    name: 'Insecure Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/808.png',
    uri: '/808',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Pale Cowboy',
      rarityScore: '0.000754',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 201,
    },
  },
  {
    index: 809,
    name: 'Narrow Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/809.png',
    uri: '/809',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.004594',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 881,
    },
  },
  {
    index: 810,
    name: 'Tremendous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/810.png',
    uri: '/810',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.005302',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 914,
    },
  },
  {
    index: 811,
    name: 'Recent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/811.png',
    uri: '/811',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003592',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 752,
    },
  },
  {
    index: 812,
    name: 'Avaricious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/812.png',
    uri: '/812',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.002881',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 545,
    },
  },
  {
    index: 813,
    name: 'Harsh Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/813.png',
    uri: '/813',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.003223',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 669,
    },
  },
  {
    index: 814,
    name: 'Blushing Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/814.png',
    uri: '/814',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.004081',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 843,
    },
  },
  {
    index: 815,
    name: 'United Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/815.png',
    uri: '/815',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Avatar',
      rarityScore: '0.000659',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 180,
    },
  },
  {
    index: 816,
    name: 'Aware Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/816.png',
    uri: '/816',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.000631',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 173,
    },
  },
  {
    index: 817,
    name: 'Enraged Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/817.png',
    uri: '/817',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002259',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 314,
    },
  },
  {
    index: 818,
    name: 'Embarrassed Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/818.png',
    uri: '/818',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.002585',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 413,
    },
  },
  {
    index: 819,
    name: 'Guilty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/819.png',
    uri: '/819',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.000634',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 174,
    },
  },
  {
    index: 820,
    name: 'Trivial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/820.png',
    uri: '/820',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Sweat Band',
      rarityScore: '0.002626',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 426,
    },
  },
  {
    index: 821,
    name: 'Quarterly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/821.png',
    uri: '/821',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Cop',
      rarityScore: '0.003647',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 766,
    },
  },
  {
    index: 822,
    name: 'Humiliating Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/822.png',
    uri: '/822',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.003309',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 693,
    },
  },
  {
    index: 823,
    name: 'Utilized Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/823.png',
    uri: '/823',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.002787',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 501,
    },
  },
  {
    index: 824,
    name: 'Fake Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/824.png',
    uri: '/824',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.011371',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 990,
    },
  },
  {
    index: 825,
    name: 'Muddy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/825.png',
    uri: '/825',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Avatar',
      rarityScore: '0.000543',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 155,
    },
  },
  {
    index: 826,
    name: 'Angelobtc Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/826.png',
    uri: '/826',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Sweat Band',
      rarityScore: '0.003605',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 756,
    },
  },
  {
    index: 827,
    name: 'Husky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/827.png',
    uri: '/827',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.014294',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 1000,
    },
  },
  {
    index: 828,
    name: 'Bare Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/828.png',
    uri: '/828',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.002945',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 576,
    },
  },
  {
    index: 829,
    name: 'Putrid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/829.png',
    uri: '/829',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Visor',
      rarityScore: '0.002492',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 383,
    },
  },
  {
    index: 830,
    name: 'Funny Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/830.png',
    uri: '/830',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.002432',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 367,
    },
  },
  {
    index: 831,
    name: 'Fond Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/831.png',
    uri: '/831',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Normal',
      hats: 'Third Eye',
      rarityScore: '0.000404',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 106,
    },
  },
  {
    index: 832,
    name: 'Hasty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/832.png',
    uri: '/832',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.001010',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 229,
    },
  },
  {
    index: 833,
    name: 'Prickly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/833.png',
    uri: '/833',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.003098',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 633,
    },
  },
  {
    index: 834,
    name: 'Good Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/834.png',
    uri: '/834',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.006268',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 922,
    },
  },
  {
    index: 835,
    name: 'Truthful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/835.png',
    uri: '/835',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Visor',
      rarityScore: '0.002999',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 597,
    },
  },
  {
    index: 836,
    name: 'Honorable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/836.png',
    uri: '/836',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.000332',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 75,
    },
  },
  {
    index: 837,
    name: 'Clumsy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/837.png',
    uri: '/837',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002817',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 514,
    },
  },
  {
    index: 838,
    name: 'Honored Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/838.png',
    uri: '/838',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'VR Goggles',
      hats: 'Astronaut',
      rarityScore: '0.003475',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 726,
    },
  },
  {
    index: 839,
    name: 'Heavy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/839.png',
    uri: '/839',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.000292',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 68,
    },
  },
  {
    index: 840,
    name: 'Bagsy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/840.png',
    uri: '/840',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.004250',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 863,
    },
  },
  {
    index: 841,
    name: 'Chilly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/841.png',
    uri: '/841',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002629',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 427,
    },
  },
  {
    index: 842,
    name: 'Cheery Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/842.png',
    uri: '/842',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.003820',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 805,
    },
  },
  {
    index: 843,
    name: 'Expert Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/843.png',
    uri: '/843',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Red Tassle',
      rarityScore: '0.002374',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 347,
    },
  },
  {
    index: 844,
    name: 'Downright Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/844.png',
    uri: '/844',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003227',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 671,
    },
  },
  {
    index: 845,
    name: 'Mundane Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/845.png',
    uri: '/845',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.004255',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 864,
    },
  },
  {
    index: 846,
    name: 'Merry Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/846.png',
    uri: '/846',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Banana Hair',
      rarityScore: '0.003039',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 610,
    },
  },
  {
    index: 847,
    name: 'Perfect Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/847.png',
    uri: '/847',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Red Tassle',
      rarityScore: '0.003158',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 651,
    },
  },
  {
    index: 848,
    name: 'Equatorial Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/848.png',
    uri: '/848',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004170',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 854,
    },
  },
  {
    index: 849,
    name: 'Powerless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/849.png',
    uri: '/849',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pearl',
      hats: 'Avatar',
      rarityScore: '0.000150',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 47,
    },
  },
  {
    index: 850,
    name: 'Loud Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/850.png',
    uri: '/850',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red',
      hats: 'Dark Cowboy',
      rarityScore: '0.003253',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 676,
    },
  },
  {
    index: 851,
    name: 'Neeraj Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/851.png',
    uri: '/851',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.002779',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 498,
    },
  },
  {
    index: 852,
    name: 'Crowded Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/852.png',
    uri: '/852',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.001137',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 234,
    },
  },
  {
    index: 853,
    name: 'Puzzled Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/853.png',
    uri: '/853',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Banana Hair',
      rarityScore: '0.003834',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 808,
    },
  },
  {
    index: 854,
    name: 'Lasting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/854.png',
    uri: '/854',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Astronaut',
      rarityScore: '0.002477',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 377,
    },
  },
  {
    index: 855,
    name: 'Crypto Cobain Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/855.png',
    uri: '/855',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Astronaut',
      rarityScore: '0.000431',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 119,
    },
  },
  {
    index: 856,
    name: 'Delirious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/856.png',
    uri: '/856',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.004424',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 873,
    },
  },
  {
    index: 857,
    name: 'Poised Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/857.png',
    uri: '/857',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003612',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 759,
    },
  },
  {
    index: 858,
    name: 'International Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/858.png',
    uri: '/858',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Cop',
      rarityScore: '0.003523',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 741,
    },
  },
  {
    index: 859,
    name: 'Best Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/859.png',
    uri: '/859',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Red Tassle',
      rarityScore: '0.002783',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 500,
    },
  },
  {
    index: 860,
    name: 'Loving Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/860.png',
    uri: '/860',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.007518',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 934,
    },
  },
  {
    index: 861,
    name: 'Gargantuan Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/861.png',
    uri: '/861',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.001969',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 268,
    },
  },
  {
    index: 862,
    name: 'Flowery Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/862.png',
    uri: '/862',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004825',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 893,
    },
  },
  {
    index: 863,
    name: 'Attentive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/863.png',
    uri: '/863',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003283',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 684,
    },
  },
  {
    index: 864,
    name: 'Scaly Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/864.png',
    uri: '/864',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002230',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 304,
    },
  },
  {
    index: 865,
    name: 'Hilarious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/865.png',
    uri: '/865',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.000686',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 187,
    },
  },
  {
    index: 866,
    name: 'Quiet Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/866.png',
    uri: '/866',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.003361',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 703,
    },
  },
  {
    index: 867,
    name: 'Lean Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/867.png',
    uri: '/867',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.002035',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 275,
    },
  },
  {
    index: 868,
    name: 'Impassioned Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/868.png',
    uri: '/868',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans Red',
      hats: 'Third Eye',
      rarityScore: '0.000659',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 181,
    },
  },
  {
    index: 869,
    name: 'Angelic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/869.png',
    uri: '/869',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003211',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 663,
    },
  },
  {
    index: 870,
    name: 'Hard-To-Find Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/870.png',
    uri: '/870',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Red Tassle',
      rarityScore: '0.003621',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 761,
    },
  },
  {
    index: 871,
    name: 'Deficient Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/871.png',
    uri: '/871',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.003293',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 688,
    },
  },
  {
    index: 872,
    name: 'Apeswap Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/872.png',
    uri: '/872',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.003171',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 657,
    },
  },
  {
    index: 873,
    name: 'Understated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/873.png',
    uri: '/873',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000377',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 94,
    },
  },
  {
    index: 874,
    name: 'Foolish Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/874.png',
    uri: '/874',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.005040',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 907,
    },
  },
  {
    index: 875,
    name: 'Cryptonekoz Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/875.png',
    uri: '/875',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.008200',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 954,
    },
  },
  {
    index: 876,
    name: 'Periodic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/876.png',
    uri: '/876',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Banana Hair',
      rarityScore: '0.005970',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 921,
    },
  },
  {
    index: 877,
    name: 'Several Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/877.png',
    uri: '/877',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Banana Hair',
      rarityScore: '0.002956',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 582,
    },
  },
  {
    index: 878,
    name: 'Dapper Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/878.png',
    uri: '/878',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Avatar',
      rarityScore: '0.000441',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 123,
    },
  },
  {
    index: 879,
    name: 'Fearless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/879.png',
    uri: '/879',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.003216',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 667,
    },
  },
  {
    index: 880,
    name: 'Profitable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/880.png',
    uri: '/880',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.002831',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 522,
    },
  },
  {
    index: 881,
    name: 'Lined Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/881.png',
    uri: '/881',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Red Tassle',
      rarityScore: '0.002441',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 370,
    },
  },
  {
    index: 882,
    name: 'Unimportant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/882.png',
    uri: '/882',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Shades',
      hats: 'Sweat Band',
      rarityScore: '0.003820',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 806,
    },
  },
  {
    index: 883,
    name: 'Gross Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/883.png',
    uri: '/883',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.000359',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 85,
    },
  },
  {
    index: 884,
    name: 'Qualified Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/884.png',
    uri: '/884',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.002743',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 483,
    },
  },
  {
    index: 885,
    name: 'Mad Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/885.png',
    uri: '/885',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Sweat Band',
      rarityScore: '0.000331',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 73,
    },
  },
  {
    index: 886,
    name: 'Obvious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/886.png',
    uri: '/886',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Brown Hair',
      rarityScore: '0.003536',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 744,
    },
  },
  {
    index: 887,
    name: 'Intentional Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/887.png',
    uri: '/887',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.002704',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 468,
    },
  },
  {
    index: 888,
    name: 'Leading Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/888.png',
    uri: '/888',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.004216',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 859,
    },
  },
  {
    index: 889,
    name: 'Pesky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/889.png',
    uri: '/889',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Dark Cowboy',
      rarityScore: '0.002649',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 436,
    },
  },
  {
    index: 890,
    name: 'Next Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/890.png',
    uri: '/890',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Astronaut',
      rarityScore: '0.007180',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 927,
    },
  },
  {
    index: 891,
    name: 'Reckless Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/891.png',
    uri: '/891',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.002768',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 493,
    },
  },
  {
    index: 892,
    name: 'Modern Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/892.png',
    uri: '/892',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002423',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 362,
    },
  },
  {
    index: 893,
    name: 'Dense Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/893.png',
    uri: '/893',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.009701',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 970,
    },
  },
  {
    index: 894,
    name: 'Adventurous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/894.png',
    uri: '/894',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans Red',
      hats: 'Red Tassle',
      rarityScore: '0.000470',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 132,
    },
  },
  {
    index: 895,
    name: 'Elaborate Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/895.png',
    uri: '/895',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Sweat Band',
      rarityScore: '0.002133',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 288,
    },
  },
  {
    index: 896,
    name: 'Arctic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/896.png',
    uri: '/896',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Astronaut',
      rarityScore: '0.001915',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 260,
    },
  },
  {
    index: 897,
    name: 'Pompliano Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/897.png',
    uri: '/897',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.007618',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 937,
    },
  },
  {
    index: 898,
    name: 'Joyous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/898.png',
    uri: '/898',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Banana Hair',
      rarityScore: '0.008343',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 955,
    },
  },
  {
    index: 899,
    name: 'Lame Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/899.png',
    uri: '/899',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.009830',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 973,
    },
  },
  {
    index: 900,
    name: 'Cryptocred Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/900.png',
    uri: '/900',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.005849',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 919,
    },
  },
  {
    index: 901,
    name: 'Keen Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/901.png',
    uri: '/901',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.002955',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 581,
    },
  },
  {
    index: 902,
    name: 'Traumatic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/902.png',
    uri: '/902',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Cop',
      rarityScore: '0.002072',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 279,
    },
  },
  {
    index: 903,
    name: 'Ill-Fated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/903.png',
    uri: '/903',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002722',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 476,
    },
  },
  {
    index: 904,
    name: 'Ivan On Tech Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/904.png',
    uri: '/904',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Pale Cowboy',
      rarityScore: '0.000763',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 202,
    },
  },
  {
    index: 905,
    name: 'Double Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/905.png',
    uri: '/905',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.002794',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 502,
    },
  },
  {
    index: 906,
    name: 'Numb Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/906.png',
    uri: '/906',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Sweat Band',
      rarityScore: '0.002354',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 342,
    },
  },
  {
    index: 907,
    name: 'Nervous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/907.png',
    uri: '/907',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.002176',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 294,
    },
  },
  {
    index: 908,
    name: 'Winklevoss Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/908.png',
    uri: '/908',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Normal',
      hats: 'Sweat Band',
      rarityScore: '0.002282',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 324,
    },
  },
  {
    index: 909,
    name: 'Exhausted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/909.png',
    uri: '/909',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.004797',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 888,
    },
  },
  {
    index: 910,
    name: 'Crisp Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/910.png',
    uri: '/910',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.008816',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 959,
    },
  },
  {
    index: 911,
    name: 'Euphoric Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/911.png',
    uri: '/911',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.000374',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 90,
    },
  },
  {
    index: 912,
    name: 'Generous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/912.png',
    uri: '/912',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Cop',
      rarityScore: '0.002925',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 565,
    },
  },
  {
    index: 913,
    name: 'Illegal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/913.png',
    uri: '/913',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Third Eye',
      rarityScore: '0.002519',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 392,
    },
  },
  {
    index: 914,
    name: 'Hefty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/914.png',
    uri: '/914',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Third Eye',
      rarityScore: '0.003401',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 711,
    },
  },
  {
    index: 915,
    name: 'Personal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/915.png',
    uri: '/915',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.002911',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 554,
    },
  },
  {
    index: 916,
    name: 'M0Nktrader Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/916.png',
    uri: '/916',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Sweat Band',
      rarityScore: '0.001915',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 261,
    },
  },
  {
    index: 917,
    name: 'Livid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/917.png',
    uri: '/917',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.003121',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 637,
    },
  },
  {
    index: 918,
    name: 'Massive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/918.png',
    uri: '/918',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Visor',
      rarityScore: '0.002660',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 441,
    },
  },
  {
    index: 919,
    name: 'Colossal Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/919.png',
    uri: '/919',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Open Smile',
      eyes: 'VR Goggles',
      hats: 'Pale Cowboy',
      rarityScore: '0.000007',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 1,
    },
  },
  {
    index: 920,
    name: 'Judicious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/920.png',
    uri: '/920',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.003671',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 771,
    },
  },
  {
    index: 921,
    name: 'Firm Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/921.png',
    uri: '/921',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.003969',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 828,
    },
  },
  {
    index: 922,
    name: 'Radiant Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/922.png',
    uri: '/922',
    attributes: {
      faceColor: 'Gold',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.000456',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 128,
    },
  },
  {
    index: 923,
    name: 'Tight Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/923.png',
    uri: '/923',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.003701',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 777,
    },
  },
  {
    index: 924,
    name: 'Creative Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/924.png',
    uri: '/924',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Visor',
      rarityScore: '0.002070',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 278,
    },
  },
  {
    index: 925,
    name: 'Pristine Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/925.png',
    uri: '/925',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.000452',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 126,
    },
  },
  {
    index: 926,
    name: 'Pessimistic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/926.png',
    uri: '/926',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.002916',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 559,
    },
  },
  {
    index: 927,
    name: 'Excitable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/927.png',
    uri: '/927',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.004597',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 882,
    },
  },
  {
    index: 928,
    name: 'Delectable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/928.png',
    uri: '/928',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.002679',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 456,
    },
  },
  {
    index: 929,
    name: 'Dangerous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/929.png',
    uri: '/929',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Visor',
      rarityScore: '0.002427',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 365,
    },
  },
  {
    index: 930,
    name: 'Previous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/930.png',
    uri: '/930',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.000422',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 113,
    },
  },
  {
    index: 931,
    name: 'Primary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/931.png',
    uri: '/931',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans Red',
      hats: 'Banana Hair',
      rarityScore: '0.000589',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 163,
    },
  },
  {
    index: 932,
    name: 'Rapid Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/932.png',
    uri: '/932',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red',
      hats: 'Visor',
      rarityScore: '0.002074',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 281,
    },
  },
  {
    index: 933,
    name: 'Each Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/933.png',
    uri: '/933',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.000430',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 118,
    },
  },
  {
    index: 934,
    name: 'Forthright Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/934.png',
    uri: '/934',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Pearl',
      hats: 'Third Eye',
      rarityScore: '0.003059',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 622,
    },
  },
  {
    index: 935,
    name: 'Lustrous Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/935.png',
    uri: '/935',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Grape Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.000871',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 213,
    },
  },
  {
    index: 936,
    name: 'Lone Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/936.png',
    uri: '/936',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Third Eye',
      rarityScore: '0.003456',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 720,
    },
  },
  {
    index: 937,
    name: 'Superb Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/937.png',
    uri: '/937',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.002315',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 333,
    },
  },
  {
    index: 938,
    name: 'Infatuated Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/938.png',
    uri: '/938',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.002837',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 528,
    },
  },
  {
    index: 939,
    name: 'Unacceptable Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/939.png',
    uri: '/939',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'VR Goggles',
      hats: 'Brown Hair',
      rarityScore: '0.002359',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 345,
    },
  },
  {
    index: 940,
    name: 'Rewarding Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/940.png',
    uri: '/940',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Normal',
      hats: 'Astronaut',
      rarityScore: '0.000588',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 162,
    },
  },
  {
    index: 941,
    name: 'Puzzling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/941.png',
    uri: '/941',
    attributes: {
      faceColor: 'Pale Zombie',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'VR Goggles',
      hats: 'Dark Cowboy',
      rarityScore: '0.000637',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 175,
    },
  },
  {
    index: 942,
    name: 'Suppoman Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/942.png',
    uri: '/942',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Happy',
      hats: 'Visor',
      rarityScore: '0.006698',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 925,
    },
  },
  {
    index: 943,
    name: 'Flaky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/943.png',
    uri: '/943',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Dark Cowboy',
      rarityScore: '0.008123',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 952,
    },
  },
  {
    index: 944,
    name: 'Automatic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/944.png',
    uri: '/944',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004043',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 840,
    },
  },
  {
    index: 945,
    name: 'Metallic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/945.png',
    uri: '/945',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.002746',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 488,
    },
  },
  {
    index: 946,
    name: 'Elastic Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/946.png',
    uri: '/946',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.002948',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 578,
    },
  },
  {
    index: 947,
    name: 'Modest Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/947.png',
    uri: '/947',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Cop',
      rarityScore: '0.002833',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 523,
    },
  },
  {
    index: 948,
    name: 'Awkward Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/948.png',
    uri: '/948',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.003180',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 659,
    },
  },
  {
    index: 949,
    name: 'Antique Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/949.png',
    uri: '/949',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.002853',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 535,
    },
  },
  {
    index: 950,
    name: 'Hurtful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/950.png',
    uri: '/950',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.002973',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 590,
    },
  },
  {
    index: 951,
    name: 'Posh Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/951.png',
    uri: '/951',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Shades',
      hats: 'Pale Cowboy',
      rarityScore: '0.000122',
      rarityTierNumber: 5,
      rarityTierName: 'Magic Monkey',
      rarityOverallRank: 27,
    },
  },
  {
    index: 952,
    name: 'Powerful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/952.png',
    uri: '/952',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Cop',
      rarityScore: '0.002187',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 296,
    },
  },
  {
    index: 953,
    name: 'Gullible Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/953.png',
    uri: '/953',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Pale Cowboy',
      rarityScore: '0.000777',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 204,
    },
  },
  {
    index: 954,
    name: 'Icky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/954.png',
    uri: '/954',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Zombie Purple',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000139',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 37,
    },
  },
  {
    index: 955,
    name: 'Graceful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/955.png',
    uri: '/955',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Closed',
      hats: 'Sweat Band',
      rarityScore: '0.003647',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 767,
    },
  },
  {
    index: 956,
    name: 'Neat Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/956.png',
    uri: '/956',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.002990',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 596,
    },
  },
  {
    index: 957,
    name: 'Second-Hand Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/957.png',
    uri: '/957',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Closed',
      hats: 'Third Eye',
      rarityScore: '0.002725',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 477,
    },
  },
  {
    index: 958,
    name: 'Elon Musk Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/958.png',
    uri: '/958',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Shades',
      hats: 'Pale Cowboy',
      rarityScore: '0.000834',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 209,
    },
  },
  {
    index: 959,
    name: 'Joyful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/959.png',
    uri: '/959',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Dark Cowboy',
      rarityScore: '0.003673',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 772,
    },
  },
  {
    index: 960,
    name: 'Rectangular Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/960.png',
    uri: '/960',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Pink Sunglasses',
      hats: 'Astronaut',
      rarityScore: '0.000447',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 124,
    },
  },
  {
    index: 961,
    name: 'Parallel Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/961.png',
    uri: '/961',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Red Sunglasses',
      hats: 'Red Tassle',
      rarityScore: '0.004850',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 895,
    },
  },
  {
    index: 962,
    name: 'Honest Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/962.png',
    uri: '/962',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Red Tassle',
      rarityScore: '0.002684',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 458,
    },
  },
  {
    index: 963,
    name: 'Grateful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/963.png',
    uri: '/963',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'VR Goggles',
      hats: 'Banana Hair',
      rarityScore: '0.003477',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 727,
    },
  },
  {
    index: 964,
    name: 'Defensive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/964.png',
    uri: '/964',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Pink Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000167',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 52,
    },
  },
  {
    index: 965,
    name: 'Needy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/965.png',
    uri: '/965',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Normal',
      hats: 'Visor',
      rarityScore: '0.002349',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 340,
    },
  },
  {
    index: 966,
    name: 'Boxmining Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/966.png',
    uri: '/966',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Shades',
      hats: 'Third Eye',
      rarityScore: '0.004003',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 834,
    },
  },
  {
    index: 967,
    name: 'Exciting Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/967.png',
    uri: '/967',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Banana Hair',
      rarityScore: '0.003084',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 631,
    },
  },
  {
    index: 968,
    name: 'Odd Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/968.png',
    uri: '/968',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Brown Hair',
      rarityScore: '0.002542',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 398,
    },
  },
  {
    index: 969,
    name: 'Mild Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/969.png',
    uri: '/969',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Shades',
      hats: 'Visor',
      rarityScore: '0.002677',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 453,
    },
  },
  {
    index: 970,
    name: 'Earnest Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/970.png',
    uri: '/970',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002742',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 482,
    },
  },
  {
    index: 971,
    name: 'Competent Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/971.png',
    uri: '/971',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Happy',
      hats: 'Red Tassle',
      rarityScore: '0.007323',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 931,
    },
  },
  {
    index: 972,
    name: 'Noted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/972.png',
    uri: '/972',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Pearl',
      hats: 'Sweat Band',
      rarityScore: '0.000432',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 120,
    },
  },
  {
    index: 973,
    name: 'Ready Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/973.png',
    uri: '/973',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red',
      hats: 'Red Tassle',
      rarityScore: '0.002016',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 274,
    },
  },
  {
    index: 974,
    name: 'Grotesque Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/974.png',
    uri: '/974',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.003578',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 750,
    },
  },
  {
    index: 975,
    name: 'Trifling Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/975.png',
    uri: '/975',
    attributes: {
      faceColor: 'Ripe Banana',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.003226',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 670,
    },
  },
  {
    index: 976,
    name: 'Expensive Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/976.png',
    uri: '/976',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Banana Hair',
      rarityScore: '0.002862',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 539,
    },
  },
  {
    index: 977,
    name: 'Quizzical Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/977.png',
    uri: '/977',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Closed',
      hats: 'Astronaut',
      rarityScore: '0.002432',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 368,
    },
  },
  {
    index: 978,
    name: 'Mammoth Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/978.png',
    uri: '/978',
    attributes: {
      faceColor: 'Highlighter Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Brown Hair',
      rarityScore: '0.007977',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 948,
    },
  },
  {
    index: 979,
    name: 'Timely Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/979.png',
    uri: '/979',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans',
      hats: 'Astronaut',
      rarityScore: '0.002477',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 378,
    },
  },
  {
    index: 980,
    name: 'Knobby Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/980.png',
    uri: '/980',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Earings',
      mouths: 'Open',
      eyes: 'Pearl',
      hats: 'Cop',
      rarityScore: '0.000159',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 51,
    },
  },
  {
    index: 981,
    name: 'Sleepy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/981.png',
    uri: '/981',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Visor',
      rarityScore: '0.002745',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 485,
    },
  },
  {
    index: 982,
    name: 'Justinnft Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/982.png',
    uri: '/982',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Happy',
      hats: 'Third Eye',
      rarityScore: '0.011664',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 992,
    },
  },
  {
    index: 983,
    name: 'Exalted Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/983.png',
    uri: '/983',
    attributes: {
      faceColor: 'Moon Blue',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Happy',
      hats: 'Avatar',
      rarityScore: '0.001837',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 256,
    },
  },
  {
    index: 984,
    name: 'Shabby Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/984.png',
    uri: '/984',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.002519',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 393,
    },
  },
  {
    index: 985,
    name: 'Hearty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/985.png',
    uri: '/985',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Pink Sunglasses',
      hats: 'Visor',
      rarityScore: '0.003358',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 702,
    },
  },
  {
    index: 986,
    name: 'Ripe Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/986.png',
    uri: '/986',
    attributes: {
      faceColor: 'Brown',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Lazer',
      hats: 'Dark Cowboy',
      rarityScore: '0.000895',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 216,
    },
  },
  {
    index: 987,
    name: 'Forceful Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/987.png',
    uri: '/987',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Sans',
      hats: 'Brown Hair',
      rarityScore: '0.002679',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 457,
    },
  },
  {
    index: 988,
    name: 'Mushy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/988.png',
    uri: '/988',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Maroon',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Shades',
      hats: 'Brown Hair',
      rarityScore: '0.003034',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 606,
    },
  },
  {
    index: 989,
    name: 'Dreary Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/989.png',
    uri: '/989',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Albino',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Cop',
      rarityScore: '0.000436',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 121,
    },
  },
  {
    index: 990,
    name: 'Cz Binance Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/990.png',
    uri: '/990',
    attributes: {
      faceColor: 'ApeSwap Brown',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Red Sunglasses',
      hats: 'Third Eye',
      rarityScore: '0.004857',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 896,
    },
  },
  {
    index: 991,
    name: 'Nifty Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/991.png',
    uri: '/991',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Army Green',
      frames: 'Main Frame',
      mouths: 'Open',
      eyes: 'Sans Red',
      hats: 'Banana Hair',
      rarityScore: '0.000528',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 149,
    },
  },
  {
    index: 992,
    name: 'Jack Dorsey Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/992.png',
    uri: '/992',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Maroon',
      frames: 'Earings',
      mouths: 'Sad',
      eyes: 'Red Sunglasses',
      hats: 'Banana Hair',
      rarityScore: '0.000175',
      rarityTierNumber: 4,
      rarityTierName: 'Prime Primate',
      rarityOverallRank: 57,
    },
  },
  {
    index: 993,
    name: 'Mealy Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/993.png',
    uri: '/993',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Pearl',
      hats: 'Red Tassle',
      rarityScore: '0.002434',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 369,
    },
  },
  {
    index: 994,
    name: 'Easy-Going Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/994.png',
    uri: '/994',
    attributes: {
      faceColor: 'Green Grey',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Sad',
      eyes: 'Closed',
      hats: 'Dark Cowboy',
      rarityScore: '0.002197',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 300,
    },
  },
  {
    index: 995,
    name: 'Gracious Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/995.png',
    uri: '/995',
    attributes: {
      faceColor: 'Pale Pink',
      baseColor: 'Zombie Purple',
      frames: 'Main Frame',
      mouths: 'Happy',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.003700',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 776,
    },
  },
  {
    index: 996,
    name: 'Concrete Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/996.png',
    uri: '/996',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Dark Grey',
      frames: 'Main Frame',
      mouths: 'Open Smile',
      eyes: 'Happy',
      hats: 'Cop',
      rarityScore: '0.001360',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 243,
    },
  },
  {
    index: 997,
    name: 'Milky Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/997.png',
    uri: '/997',
    attributes: {
      faceColor: 'Burnt Orange',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Gold Tooth',
      eyes: 'Sans',
      hats: 'Cop',
      rarityScore: '0.000418',
      rarityTierNumber: 3,
      rarityTierName: 'Alluring Ape',
      rarityOverallRank: 111,
    },
  },
  {
    index: 998,
    name: 'Entire Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/998.png',
    uri: '/998',
    attributes: {
      faceColor: 'Smoke Grey',
      baseColor: 'Hazel',
      frames: 'Main Frame',
      mouths: 'Buck Teeth',
      eyes: 'Red',
      hats: 'Brown Hair',
      rarityScore: '0.002145',
      rarityTierNumber: 2,
      rarityTierName: 'Original Orangutan',
      rarityOverallRank: 291,
    },
  },
  {
    index: 999,
    name: 'Old Ape',
    image: 'https://raw.githubusercontent.com/ApeSwapFinance/non-fungible-apes/main/images/999.png',
    uri: '/999',
    attributes: {
      faceColor: 'Blood Orange',
      baseColor: 'Orangutan',
      frames: 'Main Frame',
      mouths: 'Stale',
      eyes: 'Normal',
      hats: 'Brown Hair',
      rarityScore: '0.002845',
      rarityTierNumber: 1,
      rarityTierName: 'Common Chimp',
      rarityOverallRank: 532,
    },
  },
]

export default Nfts
