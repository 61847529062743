import { Menu as UikitMenu } from '@apeswapfinance/uikit'
import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import { CHAIN_ID } from 'config/constants/chains'
import { allLanguages } from 'config/localisation/languageCodes'
import { LanguageContext } from 'contexts/Localisation/languageContext'
import useAuth from 'hooks/useAuth'
import useSelectNetwork from 'hooks/useSelectNetwork'
import useTheme from 'hooks/useTheme'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNetworkChainId, useProfile, useTokenPrices } from 'state/hooks'
import bscConfig from './chains/bscConfig'
import etherConfig from './chains/etherConfig'
// {
//   id: -1,
//   externalLink: '/Winery_Swap_User_Guides.pdf',
//   type: 'pdf',
//   guideText: 'Winery Swap User Guides',
//   thumbnail: '/images/guide/SwapGuides.png',
// },

const videoGuide = [
  {
    id: -2,
    src: 'https://www.youtube.com/embed/9aqAmPr2cys?rel=0&autoplay=1',
    thumbnail: '/images/guide/Winery_Introduction.jpg',
    guideText: 'Introduction',
    externalLink: 'https://www.youtube.com/watch?v=9aqAmPr2cys',
    type: 'about',
  },
  {
    id: -1,
    src: 'https://www.youtube.com/embed/BqGYZisU4ec?rel=0&autoplay=1',
    thumbnail: '/images/guide/Decentralized.png',
    guideText: 'Decentralized Exchange',
    externalLink: 'https://www.youtube.com/watch?v=BqGYZisU4ec&ab_channel=WinerySwap',
    type: 'about',
  },
  {
    id: -3,
    src: 'https://www.youtube.com/embed/EGeyfPoFuRQ?rel=0&autoplay=1',
    thumbnail: '/images/guide/Features.png',
    guideText: 'Features',
    externalLink: 'https://www.youtube.com/watch?v=EGeyfPoFuRQ&ab_channel=WinerySwap',
    type: 'about',
  },
  {
    id: -4,
    src: 'https://www.youtube.com/embed/BANqnDrgUMo?rel=0&autoplay=1',
    thumbnail: '/images/guide/MoreThanExchange.png',
    guideText: 'More Than Just Exchange',
    externalLink: 'https://www.youtube.com/watch?v=BANqnDrgUMo&ab_channel=WinerySwap',
    type: 'about',
  },
  {
    id: -5,
    src: 'https://www.youtube.com/embed/BANqnDrgUMo?rel=0&autoplay=1',
    thumbnail: '/images/guide/AllInOne.png',
    guideText: 'All-In-One',
    externalLink: 'https://www.youtube.com/watch?v=uTUGKqpukro&ab_channel=WinerySwap',
    type: 'about',
  },

  {
    id: 0,
    src: 'https://www.youtube.com/embed/uTUGKqpukro?rel=0&autoplay=1',
    thumbnail: '/images/guide/MetamaskMobileGuide.png',
    guideText: 'Connect Metamask Mobile',
    externalLink: 'https://www.youtube.com/watch?v=r32quy4P6ew',
    type: 'useful',
  },

  {
    id: 1,
    src: 'https://www.youtube.com/embed/QfRwodOhumE?rel=0&autoplay=1',
    thumbnail: '/images/guide/GuideSwap_Moment.jpg',
    guideText: 'Swap Guide',
    externalLink: 'https://www.youtube.com/watch?v=QfRwodOhumE',
    type: 'useful',
  },
  {
    id: 2,
    src: 'https://www.youtube.com/embed/bdDD1V0lH88?rel=0&autoplay=1',
    thumbnail: '/images/guide/LiquidityGuide_Moment.jpg',
    guideText: 'Liquidity Guide',
    externalLink: 'https://www.youtube.com/watch?v=bdDD1V0lH88',
    type: 'useful',
  },
  {
    id: 3,
    src: 'https://www.youtube.com/embed/JLaQgJ-BGyE?rel=0&autoplay=1',
    thumbnail: '/images/guide/OakBarrelGuide_Moment.jpg',
    guideText: 'Oak Barrel Guide',
    externalLink: 'https://www.youtube.com/watch?v=JLaQgJ-BGyE',
    type: 'useful',
  },
  {
    id: 4,
    src: 'https://www.youtube.com/embed/WrHt5IgAmTQ?rel=0&autoplay=1',
    thumbnail: '/images/guide/ChampagneGuide_Moment.jpg',
    guideText: 'Staking Guide',
    externalLink: 'https://www.youtube.com/watch?v=WrHt5IgAmTQ',
    type: 'useful',
  },
  {
    id: 5,
    src: 'https://www.youtube.com/embed/_e8EWCiFa3A?rel=0&autoplay=1',
    thumbnail: '/images/guide/LotteryGuide_Moment.jpg',
    guideText: 'Lottery Guide',
    externalLink: 'https://www.youtube.com/watch?v=_e8EWCiFa3A',
    type: 'useful',
  },

  {
    id: 6,
    src: 'https://www.youtube.com/embed/JP-9zhYrMcE?rel=0&autoplay=1',
    thumbnail: '/images/guide/RefferalGuide.png',
    guideText: 'Referral Guide',
    externalLink: 'https://www.youtube.com/watch?v=JP-9zhYrMcE&ab_channel=WinerySwap',
    type: 'useful',
  },

  {
    id: 7,
    src: 'https://www.youtube.com/embed/JceJSbTaD8A?rel=0&autoplay=1',
    thumbnail: '/images/guide/ParticipateIDO.png',
    guideText: 'Participate IDO Guide',
    externalLink:
      'https://www.youtube.com/watch?v=JceJSbTaD8A&list=PLSuiEmS9equ1BEPraQKrEBWKZTtSTJcjL&index=2&ab_channel=WinerySwap',
    type: 'useful',
  },

  {
    id: 8,
    src: 'https://www.youtube.com/embed/bu3iK9CjBfI?rel=0&autoplay=1',
    thumbnail: '/images/guide/ClaimIDO.png',
    guideText: 'IDO Claim Guide',
    externalLink:
      'https://www.youtube.com/watch?v=bu3iK9CjBfI&list=PLSuiEmS9equ1BEPraQKrEBWKZTtSTJcjL&index=3&ab_channel=WinerySwap',
    type: 'useful',
  },

  {
    id: 9,
    src: 'https://www.youtube.com/embed/oMkmIQ83pls?rel=0&autoplay=1',
    thumbnail: '/images/guide/BuyNFT.png',
    guideText: 'Earn with NFT',
    externalLink: 'https://www.youtube.com/watch?v=oMkmIQ83pls&ab_channel=WinerySwap',
    type: 'useful',
  },

  // {
  //   id: 10,
  //   externalLink: '/Winery_Staking_Guide.pdf',
  //   type: 'pdf',
  //   guideText: 'Winery Swap Staking Guide',
  //   thumbnail: '/images/guide/StakingFarm.png',
  // },
  // {
  //   id: 11,
  //   externalLink: '/NFT_Staking_Guide.pdf',
  //   type: 'pdf',
  //   guideText: 'Winery Swap Staking NFT Guide',
  //   thumbnail: '/images/guide/StakingNFT.png',
  // },
  // {
  //   id: 12,
  //   externalLink: '/CONNECT_METAMASK_WALLET_BY_PHONE_Winery_Swap.pdf',
  //   type: 'pdf',
  //   guideText: 'How To Connect Metamask By Phone',
  //   thumbnail: '/images/guide/MobileGuide.png',
  // },
]

const Menu = (props) => {
  const { account } = useWeb3React()

  const [parentRef, setParentRef] = useState<string | undefined>(undefined)
  const chainId = useNetworkChainId()
  const { login, logout } = useAuth()
  const { switchNetwork } = useSelectNetwork()
  const { isDark, toggleTheme } = useTheme()
  const { tokenPrices } = useTokenPrices()
  const bananaPriceUsd = tokenPrices?.find((token) => token.symbol === 'CORK')?.price
  const { profile } = useProfile()
  const { i18n } = useTranslation()
  const changeLang = (newLang) => {
    i18n.changeLanguage(newLang.code)
  }

  useEffect(() => {
    const getParentRef = async () => {
      const res = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/ref/parent?address=${account}`)
      if (res.data.statusCode === 200) {
        setParentRef(res?.data?.data?.address)
      }
    }
    if (account) {
      getParentRef()
    }
  }, [account])

  const currentMenu = () => {
    if (chainId === CHAIN_ID.BSC) {
      return bscConfig
    }
    if (chainId === CHAIN_ID.ETHEREUM) {
      return etherConfig
    }
    return bscConfig
  }

  return (
    <UikitMenu
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      bananaPriceUsd={bananaPriceUsd}
      links={currentMenu()}
      chainId={chainId}
      switchNetwork={switchNetwork}
      langs={allLanguages}
      setLang={changeLang}
      currentLang={i18n.language}
      profile={{
        image: profile ? profile?.rarestNft.image : null,
        noProfileLink: '/nft',
      }}
      guideData={videoGuide}
      parentRef={parentRef}
      {...props}
    />
  )
}

export default Menu
