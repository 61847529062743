import { Air } from './types'

const airs: any[] = [
  {
    id: 'air',
    isOpen: false,
    airdropEventName: 'The biggest Winery airdrop event',
    totalAirdropValue: '10,000 CORK',
    totalWinner: '200',
    totalParticipants: '100,000',
    duration: 'To be announced',
    vestingDate: 'To be announced',
    questionExpand: 'What is Winery?',
    answerExpand: [
      `WINERY is a decentralized cryptocurrency exchange project based on Binance Smart Chain (BEP20) that operates by connecting cryptocurrency traders in a peer-to-peer (P2P) manner using the Automated Market Maker Model (AMM). It is a platform that enables users to trade a huge variety of BEP-20 tokens via smart contracts to eliminate the risk of the counterparty.`,
      `The main functions of Winery DEX are token trading, liquidity supply, and other liquidity provided functions, farming and staking function, lotto function. Winery is connected to the Binance Smart Chain and supports a large number of Blockchain wallets like MetaMask, TrustWallet, and TokenPocket as well as a huge number of tokens. The rewards traders get from the main functions of Winery DEX will be paid with CORK.
      `,
    ],
    urlBannerImage: '/images/airdrops/winery.png',
    urlJoinNow: 'https://gleam.io/Y53UZ/winery-swap',
    stepHowToJoin: [
      '1. Connect your Wallet',
      '2. Click “Join now” button for accessing Gleam',
      '3. Create Gleam account',
      '4. Join Winery Telegram Group',
      '5. Join Winery Telegram Channel',
      '6. Join Winery Discord Channel',
      '7. Follow Winery on Twitter',
      '8. Retweet on Twitter',
      '9. Add your wallet address',
    ],
    title: 'Winery swap',
    description:
      'WINERY is a decentralized cryptocurrency exchange project based on Binance Smart Chain (BEP20) that operates by connecting cryptocurrency traders in a peer-to-peer (P2P) manner using the Automated Market Maker Model (AMM). It is a platform that enables users to trade a huge variety of BEP-20 tokens via smart contracts to eliminate the risk of the counterparty.',
    isAnnounced: true,
  },
  {
    id: 'air1',
    isOpen: false,
    airdropEventName: 'FIRST HALO COIN AIRDROP EVENT',
    totalAirdropValue: '7,000 PGC',
    totalWinner: '1,500',
    totalParticipants: '10,000',
    duration: 'To be announced',
    vestingDate: 'To be announced',
    questionExpand: 'What is COIN HALO?',
    answerExpand: [
      `COIN HALO is a slot machine from the coin pusher series, your task is to throw coins, which will be pushed by a moving platform and according to the domino principle, push other coins towards the basket. It also an exciting game with many game modes and special features currently being added by power up feature`,
    ],
    urlBannerImage: '/images/airdrops/coin_halo.jpg',
    urlJoinNow: 'https://gleam.io/Y53UZ/winery-swap',
    stepHowToJoin: [
      '1. Connect your Wallet',
      '2. Click “Join now” button for accessing Gleam',
      '3. Create Gleam account',
      '4. Join Halo Coin Telegram Group',
      '5. Join Halo Coin Telegram Channel',
      '6. Join Halo Coin Discord Channel',
      '7. Follow Halo Coin on Twitter',
      '8. Retweet on Twitter',
      '9. Add your wallet address',
    ],
    title: 'Winery swap',
    description:
      'COIN HALO is a slot machine from the coin pusher series, your task is to throw coins, which will be pushed by a moving platform and according to the domino principle, push other coins towards the basket. It also an exciting game with many game modes and special features currently being added by power up feature',
    isAnnounced: true,
  },
  {
    id: 'air2',
    isOpen: false,
    airdropEventName: 'POOLS PHONE FIRST AIRDROP EVENT',
    totalAirdropValue: '3,500 BUSD',
    totalWinner: '1,500',
    totalParticipants: '100,000',
    duration: 'To be announced',
    vestingDate: 'To be announced',
    questionExpand: 'What is Pools Phone?',
    answerExpand: [
      `It is more than just a smartphone, it's a PHONE-TO-EARN! POOLS phone is the world's first crypto-exclusive smartphone designed for the MZ Generation and Cryptocurrency Holders. Buying Pools Phone for gaining variety of benefits:`,
      `+  Money Making Phone: Earn money and varieties of other benefits with the POOLS Phone via Referral Reward, effortless texting, or calling
      `,
      `+  Crypto Phone: Easy and convenient crypto economy with integrated POOLS Dapp Store / DEX / NFT Store
      The world’s first NFT camera app for everyone to become an NFT creator
      `,
      `+  Security Phone: Offer the best security solution with Biometric-based Identity for secured personal information Biometric card method for secured payments
      `,
    ],
    urlBannerImage: '/images/airdrops/pools.jpg',
    urlJoinNow: 'https://gleam.io/Y53UZ/winery-swap',
    stepHowToJoin: [
      '1. Connect your Wallet',
      '2. Click “Join now” button for accessing Gleam',
      '3. Create Gleam account',
      '4. Join Pools Phone Telegram Group',
      '5. Join Pools Phone Telegram Channel',
      '6. Join Pools Phone Discord Channel',
      '7. Follow Pools Phone on Twitter',
      '8. Retweet on Twitter',
      '9. Add your wallet address',
    ],
    title: 'Winery swap',
    description: `It is more than just a smartphone, it's a PHONE-TO-EARN! POOLS phone is the world's first crypto-exclusive smartphone designed for the MZ Generation and Cryptocurrency Holders. Buying Pools Phone for gaining variety of benefits:`,
    isAnnounced: true,
  },
]

export const pastAirs: Air[] = airs.filter((air) => !air.isActive)

export default airs
